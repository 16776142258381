import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// MUI components
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    IconButton,
    Slide,
    Typography
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Externals
import { Icon } from "@iconify/react/dist/iconify.js";
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

// Cornerstone Imports
import cornerstone from "cornerstone-core";

// Internals
import AddSignatureDialog from './AddSignatureDialog';
import BulletPointTextField from './BulletPointTextField';
import DicomReport from "./DicomReport";

// Services
import ApiCalls from "../../services/try";

// Create a theme
const reportTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: '#00BFFF', // Light blue color for buttons and borders
        },
    },
});

const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    return formattedDate.replace(/ /g, '-');
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReportViewerComponent = ({ open, onClose, dicomImage, patientDetails, selectedImageDetails, selectedFilePath, selectedFileName }) => {
    const history = useHistory();
    const dicomElementRef = useRef(null);
    const [error, setError] = useState(null);

    // State for input fields
    const [impression, setImpression] = useState([]);
    const [findings, setFindings] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [notes, setNotes] = useState([]);

    const [signatureUrl, setSignatureUrl] = useState("");
    const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
    const [showReportPreview, setShowReportPreview] = useState(false);
    const [showAddSignatureDialog, setShowAddSignatureDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const isCheckingSignature = useRef(false);
    const [signatureExists, setSignatureExists] = useState(false);

    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);

    const formatReportNumber = (patientId) => {
        const currentDate = new Date();
        const yearLastTwoDigits = currentDate.getFullYear().toString().slice(-2);
        const monthAbbreviation = currentDate.toLocaleString('en-US', { month: 'short' }).toUpperCase();
        return `${yearLastTwoDigits}/${monthAbbreviation}/${patientId}`;
    };

    const formattedReport = formatReportNumber(patientDetails.patientId);

    useEffect(() => {
        if (open) {
            setLoading(true);
            setError(null);

            const companyId = secureLocalStorage.getItem("compId");
            const physicianId = secureLocalStorage.getItem("physicianId");
            const patientId = patientDetails.patientId;

            ApiCalls.getdetails(`report/getdicomreportid?reportno=${formattedReport}`)
                .then(response => {
                    if (response.status === 200 && response.data.reportid !== undefined) {
                        const reportId = response.data.reportid;
                        return ApiCalls.getdetails(
                            `companyid/${companyId}/physisicanid/${physicianId}/patientid/${patientId}/reportid/${reportId}/getdicomreport`
                        );
                    } else {
                        throw new Error("Failed to fetch report ID");
                    }
                })
                .then(response1 => {
                    if (response1.status === 200 && response1.data) {
                        const data = response1.data;
                        setImpression(data.impressions.map(item => item.reportimpressions));
                        setFindings(data.views.map(item => item.viewentry));
                        setRecommendations(data.recommendations.map(item => item.recommendations));
                        setNotes(data.notes.map(item => item.notes));
                        setIsPreviewEnabled(true);
                    } else {
                        setImpression([]);
                        setFindings([]);
                        setRecommendations([]);
                        setNotes([]);
                        throw new Error("Failed to fetch report details");
                    }
                })
                .catch(error => {
                    console.error("Error fetching report details:", error);
                    setError("Failed to load existing report details.");
                })
                .finally(() => setLoading(false));
        }
    }, [open, patientDetails]);

    useEffect(() => {
        const checkForSignature = async () => {
            if (isCheckingSignature.current) return;
            isCheckingSignature.current = true;

            const extensions = ['.png', '.jpg', '.jpeg'];
            const uuid = secureLocalStorage.getItem("UUID");

            for (const ext of extensions) {
                const filePath = `./uploads/Signatures/${uuid}${ext}`;

                try {
                    const response = await ApiCalls.viewFile("viewFile", { filePath });
                    if (response.data instanceof Blob) {
                        const url = window.URL.createObjectURL(response.data);
                        setSignatureExists(true);
                        setSignatureUrl(url);
                        setLoading(false);
                        return;
                    } else {
                        console.error("Unexpected response data type:", typeof response.data);
                    }
                } catch (error) {
                    console.error(`Error checking signature with extension ${ext}:`, error);
                }
            }

            setSignatureExists(false);
            setLoading(false);
        };

        checkForSignature();

        return () => {
            if (signatureUrl) {
                URL.revokeObjectURL(signatureUrl);
            }
        };
    }, [signatureUrl]);

    const ensureElementEnabled = () => {
        if (dicomElementRef.current && !cornerstone.getEnabledElement(dicomElementRef.current)) {
            cornerstone.enable(dicomElementRef.current);
        }
    };

    useEffect(() => {
        if (dicomImage && dicomElementRef.current) {
            cornerstone.enable(dicomElementRef.current);

            cornerstone.loadImage(dicomImage).then((image) => {
                cornerstone.displayImage(dicomElementRef.current, image);
            }).catch((error) => {
                console.error("Error loading DICOM image:", error);
            });
        }

        return () => {
            if (dicomElementRef.current) {
                cornerstone.disable(dicomElementRef.current);
            }
        };
    }, [dicomImage]);

    // Button functionalities
    const handleZoomIn = () => {
        if (dicomElementRef.current && cornerstone.getEnabledElement(dicomElementRef.current)) {
            const viewport = cornerstone.getViewport(dicomElementRef.current);
            viewport.scale += 0.1;
            cornerstone.setViewport(dicomElementRef.current, viewport);
        }
    };

    const handleZoomOut = () => {
        if (dicomElementRef.current && cornerstone.getEnabledElement(dicomElementRef.current)) {
            const viewport = cornerstone.getViewport(dicomElementRef.current);
            viewport.scale -= 0.1;
            cornerstone.setViewport(dicomElementRef.current, viewport);
        }
    };

    const handleRotateLeft = () => {
        if (dicomElementRef.current && cornerstone.getEnabledElement(dicomElementRef.current)) {
            const viewport = cornerstone.getViewport(dicomElementRef.current);
            viewport.rotation -= 90;
            cornerstone.setViewport(dicomElementRef.current, viewport);
        }
    };

    const handleRotateRight = () => {
        if (dicomElementRef.current && cornerstone.getEnabledElement(dicomElementRef.current)) {
            const viewport = cornerstone.getViewport(dicomElementRef.current);
            viewport.rotation += 90;
            cornerstone.setViewport(dicomElementRef.current, viewport);
        }
    };

    const handleFlipHorizontal = () => {
        if (dicomElementRef.current && cornerstone.getEnabledElement(dicomElementRef.current)) {
            const viewport = cornerstone.getViewport(dicomElementRef.current);
            viewport.hflip = !viewport.hflip;
            cornerstone.setViewport(dicomElementRef.current, viewport);
        }
    };

    const handleFlipVertical = () => {
        if (dicomElementRef.current && cornerstone.getEnabledElement(dicomElementRef.current)) {
            const viewport = cornerstone.getViewport(dicomElementRef.current);
            viewport.vflip = !viewport.vflip;
            cornerstone.setViewport(dicomElementRef.current, viewport);
        }
    };

    const handleResetView = () => {
        if (dicomElementRef.current && cornerstone.getEnabledElement(dicomElementRef.current)) {
            const image = cornerstone.getImage(dicomElementRef.current);
            const defaultViewport = cornerstone.getDefaultViewportForImage(dicomElementRef.current, image);
            cornerstone.setViewport(dicomElementRef.current, {
                ...defaultViewport,
                voi: {
                    windowWidth: image.windowWidth,
                    windowCenter: image.windowCenter
                },
                invert: false
            });
        }
    };

    const handleSaveDraft = async () => {
        console.log("Impression/Diagnosis:", impression);
        console.log("Findings:", findings);
        console.log("Recommendations:", recommendations);
        console.log("Notes:", notes);
        console.log(selectedImageDetails);

        const mainReportPayload = {
            reportno: formattedReport,
            reportdate: formattedDate,
            reporttype: "DICOM",
            filename: selectedFileName,
            filepath: selectedFilePath,
            patientid: patientDetails.patientId,
            companyid: secureLocalStorage.getItem("compId"),
            physicianid: secureLocalStorage.getItem("physicianId"),
            generateduserid: secureLocalStorage.getItem("UUID"),
            generateduserrole: secureLocalStorage.getItem("roleId"),
            generateddate: formattedDate,
        };

        const makeApiCall = async (url, payload) => {
            console.log(url);
            console.log(payload);

            try {
                const response = await ApiCalls.register(url, payload);
                if (response.status !== 200) {
                    throw new Error(`Failed to save data to ${url}`);
                }
                console.log(`Save successful to ${url}:`, response.data);
                return response.data;
            } catch (error) {
                console.error(`Error saving data to ${url}:`, error);
                throw error;
            }
        };

        try {
            const mainReportResponse = await makeApiCall("savedicomreport", mainReportPayload);
            const uuid = mainReportResponse.data.uuid;
            console.log("Retrieved UUID:", uuid);

            const preparePayload = (data, idField, valueField) =>
                data.length > 0
                    ? data.map((item, index) => ({
                        [idField]: uuid,
                        [valueField]: item,
                    }))
                    : null;

            const impressionPayload = preparePayload(impression, "report_impression_id", "reportimpressions");
            const notesPayload = preparePayload(notes, "reportnotesid", "notes");
            const recommendationsPayload = preparePayload(recommendations, "report_rec_id", "recommendations");
            const findingsPayload = preparePayload(findings, "reportviewid", "viewentry");

            const apiCalls = [];

            if (impressionPayload) {
                apiCalls.push(makeApiCall("savedicomreportimpression", impressionPayload));
            }
            if (notesPayload) {
                apiCalls.push(makeApiCall("savedicomreportnotes", notesPayload));
            }
            if (recommendationsPayload) {
                apiCalls.push(makeApiCall("savedicomreportrecommendation", recommendationsPayload));
            }
            if (findingsPayload) {
                apiCalls.push(makeApiCall("savedicomreportview", findingsPayload));
            }

            if (apiCalls.length > 0) {
                await Promise.all(apiCalls);
            }

            swal({
                title: "Success!",
                text: "Report details have been saved successfully.",
                icon: "success",
                timer: 2000,
                buttons: false,
            });

            setIsPreviewEnabled(true);
        } catch (error) {
            console.error("Error saving DICOM report sections:", error);
        }
    };

    const handlePreviewReport = () => {
        if (signatureExists) {
            setShowReportPreview(true);
        } else {
            swal({
                title: "Signature Required",
                text: "Please add a signature before previewing the report.",
                icon: "warning",
                buttons: ["Cancel", "Add Signature"]
            }).then((willAdd) => {
                if (willAdd) {
                    history.push('/signature');
                }
            });
        }
    };

    const handleAddSignature = () => {
        setShowAddSignatureDialog(true);
    };

    const handleSignatureUploadComplete = () => {
        setShowAddSignatureDialog(false);
    };

    const handleClosePreviewReport = () => {
        setShowReportPreview(false);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    return (
        <ThemeProvider theme={reportTheme}>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <Box sx={{ backgroundColor: "#080F18", height: "100vh", overflow: "hidden", color: "white", display: 'flex', flexDirection: 'column' }}>
                    {/* Header Section */}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                        sx={{ backgroundColor: "#091423" }}
                    >
                        {/* Container for Typography Items */}
                        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: "Martel Sans",
                                    fontSize: "18px",
                                    fontWeight: 700,
                                    lineHeight: "27.36px",
                                    textAlign: "center",
                                }}
                            >
                                Report # {formattedReport}
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: "Martel Sans",
                                    fontSize: "18px",
                                    fontWeight: 700,
                                    lineHeight: "27.36px",
                                    textAlign: "center",
                                }}
                            >
                                Date {formattedDate}
                            </Typography>
                        </Box>

                        {/* Close Button */}
                        <IconButton onMouseDown={handleMouseDown} onClick={onClose} sx={{ color: "white" }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Main Content Area */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, padding: 2, gap: 2, overflow: 'hidden' }}>
                        {/* Left Column (split into top and bottom) */}
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2, maxHeight: '100%', overflow: 'auto' }}>
                            {/* Top Part of Left Column (60%) */}
                            <Box ref={dicomElementRef} sx={{ flex: 6, backgroundColor: '#091423', padding: 2, borderRadius: 2, overflow: 'hidden' }}>
                                {dicomImage && (
                                    <Box
                                        component="img"
                                        src={dicomImage}
                                        alt="DICOM Image"
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: 2,
                                            maxHeight: '450px',
                                        }}
                                    />
                                )}

                                {/* Toolbar for Actions */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#091423",
                                        py: 1,
                                        width: "100%",
                                        minHeight: '60px',
                                        gap: 1,
                                    }}
                                >
                                    {/* Toolbar buttons (Zoom, Rotate, Flip, Reset) */}
                                    {[
                                        { action: handleZoomIn, label: 'Zoom In', icon: 'tabler:zoom-in' },
                                        { action: handleZoomOut, label: 'Zoom Out', icon: 'tabler:zoom-out' },
                                        { action: handleRotateLeft, label: 'Rotate Left', icon: 'ic:outline-rotate-left' },
                                        { action: handleRotateRight, label: 'Rotate Right', icon: 'ic:outline-rotate-right' },
                                        { action: handleFlipHorizontal, label: 'Flip Horizontal', icon: 'mdi:flip-horizontal' },
                                        { action: handleFlipVertical, label: 'Flip Vertical', icon: 'mdi:flip-vertical' },
                                        { action: handleResetView, label: 'Reset View', icon: 'bx:reset' }
                                    ].map(({ action, label, icon }, index) => (
                                        <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                                            <IconButton onClick={action} sx={{ color: "white" }}>
                                                <Icon icon={icon} />
                                            </IconButton>
                                            <Typography variant="caption" sx={{ color: "white" }}>{label}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>

                            {/* Bottom Part of Left Column (40%) */}
                            <Box sx={{ flex: 4, backgroundColor: '#091423', padding: 2, borderRadius: 2, overflowY: 'auto' }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        marginBottom: 2,
                                        fontFamily: "Martel Sans",
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "27.36px",
                                        backgroundColor: '#009FE3',
                                        color: 'black',
                                        borderRadius: '12px',
                                        textAlign: 'center',
                                        display: 'block',
                                        width: '100%'
                                    }}
                                >
                                    Impression / Diagnosis
                                </Typography>
                                <BulletPointTextField
                                    value={impression}
                                    onChange={setImpression}
                                    minrows={5}
                                />
                            </Box>
                        </Box>

                        {/* Right Column (split into top and bottom, 40-60) */}
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2, maxHeight: '100%', overflow: 'auto' }}>
                            {/* Top Part of Right Column (40% height) */}
                            <Box sx={{ flex: 3, display: 'flex', flexDirection: 'row', gap: 2 }}>
                                {/* Left Part of Top Right Column */}
                                <Box sx={{ flex: 1, backgroundColor: '#091423', padding: 2, borderRadius: 2, overflowY: 'auto' }}>
                                    <Typography variant="h6" sx={{
                                        marginBottom: 2,
                                        fontFamily: "Martel Sans",
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "27.36px",
                                        backgroundColor: '#009FE3',
                                        color: 'black',
                                        borderRadius: '12px',
                                        textAlign: 'center',
                                        display: 'block',
                                        width: '100%'
                                    }}>
                                        {`${secureLocalStorage.getItem("PatientName")} Information`}
                                    </Typography>
                                    {/* Content area for patient details */}
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "white",
                                            fontFamily: "Martel Sans",
                                            fontSize: "15px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            marginBottom: 1,
                                        }}
                                    >
                                        {`${secureLocalStorage.getItem("PatientName")} ID`}: {patientDetails.patientId}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "white",
                                            fontFamily: "Martel Sans",
                                            fontSize: "15px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Name: {patientDetails.firstName}
                                    </Typography>
                                </Box>

                                {/* Right Part of Top Right Column */}
                                <Box sx={{ flex: 1, backgroundColor: '#091423', padding: 2, borderRadius: 2, overflowY: 'auto' }}>
                                    <Typography variant="h6" sx={{
                                        marginBottom: 2,
                                        fontFamily: "Martel Sans",
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "27.36px",
                                        backgroundColor: '#009FE3',
                                        color: 'black',
                                        borderRadius: '12px',
                                        textAlign: 'center',
                                        display: 'block',
                                        width: '100%',
                                    }}>
                                        Views / Findings
                                    </Typography>

                                    <BulletPointTextField
                                        value={findings}
                                        onChange={setFindings}
                                        minrows={6}
                                    />
                                </Box>
                            </Box>

                            {/* Bottom Part of Right Column (60% height, split into top and bottom) */}
                            <Box sx={{ flex: 7, display: 'flex', flexDirection: 'column', gap: 2, overflowY: 'auto' }}>
                                {/* Top Part of Bottom Right Column */}
                                <Box sx={{ flex: 1, backgroundColor: '#091423', padding: 2, borderRadius: 2, overflowY: 'auto' }}>
                                    <Typography variant="h6" sx={{
                                        marginBottom: 2,
                                        fontFamily: "Martel Sans",
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "27.36px",
                                        backgroundColor: '#009FE3',
                                        color: 'black',
                                        borderRadius: '12px',
                                        textAlign: 'center',
                                        display: 'block',
                                        width: '100%'
                                    }}>
                                        Recommendations
                                    </Typography>
                                    <BulletPointTextField
                                        value={recommendations}
                                        onChange={setRecommendations}
                                        minrows={5}
                                    />
                                </Box>

                                {/* Bottom Part of Bottom Right Column */}
                                <Box sx={{ flex: 1, backgroundColor: '#091423', padding: 2, borderRadius: 2, overflowY: 'auto' }}>
                                    <Typography variant="h6" sx={{
                                        marginBottom: 2,
                                        fontFamily: "Martel Sans",
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "27.36px",
                                        backgroundColor: '#009FE3',
                                        color: 'black',
                                        borderRadius: '12px',
                                        textAlign: 'center',
                                        display: 'block',
                                        width: '100%'
                                    }}>
                                        Notes
                                    </Typography>
                                    <BulletPointTextField
                                        value={notes}
                                        onChange={setNotes}
                                        minrows={5}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* Bottom Button Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: 2,
                            backgroundColor: '#080F18',
                            gap: 1,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#054A68',
                                color: 'white',
                                borderRadius: '8px',
                                textTransform: 'none',
                            }}
                            onMouseDown={handleMouseDown}
                            onClick={handleSaveDraft}
                            startIcon={<Icon icon={'hugeicons:license-draft'} />}
                        >
                            Save Draft
                        </Button>
                        {!signatureExists && (
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#054A68',
                                    color: 'white',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                }}
                                onMouseDown={handleMouseDown}
                                onClick={handleAddSignature}
                                startIcon={<Icon icon={'fluent:signature-16-filled'} />}
                            >
                                Add Signature
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#054A68',
                                color: 'white',
                                borderRadius: '8px',
                                textTransform: 'none',
                            }}
                            onMouseDown={handleMouseDown}
                            onClick={handlePreviewReport}
                            startIcon={<Icon icon={'material-symbols-light:preview'} />}
                            disabled={!isPreviewEnabled}
                        >
                            Preview Report
                        </Button>
                    </Box>

                </Box>
            </Dialog>

            {/* Add Report Dialog */}
            {showReportPreview && (
                <DicomReport
                    open={showReportPreview}
                    onClose={handleClosePreviewReport}  // Provide a way to close the DicomReport
                    signatureUrl={signatureUrl}  // Pass signature URL as a prop
                    dicomImage={dicomImage}
                    patientDetails={patientDetails}
                    formattedReport={formattedReport}
                />
            )}

            {/* Add Signature Dialog */}
            {showAddSignatureDialog && (
                <AddSignatureDialog
                    open={showAddSignatureDialog}
                    onClose={() => setShowAddSignatureDialog(false)}
                    onSignatureUpload={handleSignatureUploadComplete}
                />
            )}

        </ThemeProvider>
    );
};

export default ReportViewerComponent;
