import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

//components
import DeviceImg from "../../assets/images/devices.png";
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';
import MeasurementList from "../Dashboard/components/MeasurementList";
import DiComViewer from "../ManageDiagnostics/DiComViewer";
import DicomViewer from "./DiComViewer";
import DiComViewerComponent from "./DiComViewerComponent";

//mui-components
import { Clear, Close, Home, Info, Visibility } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { ThemeProvider, createTheme, emphasize, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

//externals
import { Icon } from '@iconify/react';
import Chart from 'chart.js/auto';
import { format } from "date-fns";
import moment from "moment";
import 'react-image-gallery/styles/css/image-gallery.css';
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

//services
import ApiCalls from "../../services/try";

// DICOM Viewer import
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";

// ----------------------------------------------------------------------

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography component="div" sx={{ p: 3 }}>
          {children}
        </Typography>
      )}
    </div>
  );
}

// ----------------------------------------------------------------------

const PatientView = () => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const dialogHeight = isSmallScreen ? '70vh' : '80vh'; // 70% height for small screens, 80% for larger screens
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

  const [tabValue, setTabValue] = useState(0);

  const [filterOptions, setFilterOptions] = useState([
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'week',
      label: 'One Week',
    },
    {
      value: 'month',
      label: 'One Month',
    },
    {
      value: 'All',
      label: 'All',
    },
    // {
    //   value: 'date',
    //   label: 'Selected Dates',
    // },
    // {
    //   value: 'specificdate',
    //   label: 'Date',
    // },
  ]);

  const [filters, setFilters] = useState({
    date: null,
    imageType: '',
    scanType: '',
  });

  const [patientId, setPatientId] = useState('');
  const [paramName, setParamName] = useState('');
  const [imageType, setImageType] = useState("Standard");
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [scanType, setScanType] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [bodySite, setBodySite] = useState("");
  const [error, setError] = useState("");
  const [filePath, setFilePath] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [docURL, setDocURL] = useState("");
  const [hmrNo, setHmrNo] = useState("");

  const [durationFilterOptions, setDurationFilterOptions] = useState([
    { value: "60", label: "Last 1 Hour" },
    { value: "120", label: "Last 2 Hours" },
    { value: "180", label: "Last 3 Hours" },
    { value: "240", label: "Last 4 Hours" },
    { value: "300", label: "Last 5 Hours" },
    { value: "360", label: "Last 6 Hours" },
    { value: "420", label: "Last 7 Hours" },
  ]);
  
  // Set the default filter to "Today"
  const [selectedDurationFilter, setSelectedDurationFilter] = useState(
    durationFilterOptions.find((option) => option.value === "60")
  );

  // Set the default filter to "Today"
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    filterOptions.find((option) => option.value === "today")
  );

  const [patientDetails, setPatientDetails] = useState([]);
  const [physicianDetails, setPhysicianDetails] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [selectedParamFilter, setSelectedParamFilter] = useState([]);
  const [measureParameters, setMeasureParameters] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [images, setImages] = useState([]);
  const [patientHistory, setPatientHistory] = useState([]);
  const [patients, setPatients] = useState([]);
  const [seriesImages, setSeriesImages] = useState([]);
  const [imageTypes, setImageTypes] = useState([]);
  const [scanTypes, setScanTypes] = useState([]);
  const [DICOMImageDetails, setDICOMImageDetails] = useState([]);

  const [openDateFilter, setOpenDateFilter] = useState(null);
  const [openParamFilter, setOpenParamFilter] = useState(null);
  const [openDurationFilter, setOpenDurationFilter] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [dicomFile, setDicomFile] = useState(null);

  const [loadPatientDetails, setLoadPatientDetails] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [patientIdError, setPatientIdError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [imageTypeError, setImageTypeError] = useState(false);
  const [filePathError, setFilePathError] = useState(false);
  const [openImageViewDialog, setOpenImageViewDialog] = useState(false);
  const [openDocViewDialog, setOpenDocViewDialog] = useState(false);
  const [openDiComViewer, setOpenDiComViewer] = useState(false);
  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [flipVertical, setFlipVertical] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [openImageDetails, setOpenImageDetails] = useState(false);
  const [dialogData, setDialogData] = useState({}); // Holds the data for the dialog

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);

  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  const formatDate = (date) => {
    return format(new Date(date), "MM/dd/yyyy");
  };

  const maxDate = formatDate(new Date());

  const loadPatientDetailsById = (patientId) => {
    setLoadPatientDetails(true);
    ApiCalls.getdetails("getpatient/" + patientId + "/patients")
      .then((response) => {
        if (response.data.patient != undefined) {
          setPatientDetails(response.data.patient[0]);
          setHmrNo(response.data.patient[0].patientId);
          setName(response.data.patient[0].firstName || response.data.patient[0].lastName
            ? `${response.data.patient[0].firstName || ""} ${response.data.patient[0].lastName || ""
              }`.trim()
            : "No Name");
          setDob(response.data.patient[0].dob);
          setGender(response.data.patient[0].gender);

          setLoadPatientDetails(false);
        } else {
          setLoadPatientDetails(false);
          setPatientDetails([]);
        }
      })
  }

  const loadPhysicianDetailsById = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/getPhysician")
      .then((response) => {
        if (response.data.patient !== undefined) {
          setPhysicianDetails(response.data.patient)
        } else {
          setPhysicianDetails([]);
        }
      })
  }
  const loadDeviceDetailsById = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/getdevices")
      .then((response) => {
        if (response.data.device !== undefined) {
          setDeviceDetails(response.data.device)
        } else {
          setDeviceDetails([])
        }
      })
  }
  const loadMeasurementDetailsById = (patientId) => {
    setLoading(true);
    const paramName = "All";
    ApiCalls.getdetails(`patient/${patientId}/parameter/${paramName}/${selectedDateFilter.value}/getmeasurementwithfilter/0/0/${selectedDurationFilter.value}`)
      .then((response) => {
        if (response.data.measurement !== undefined) {
          setMeasurements(response.data.measurement);
          setLoading(false);
        } else {
          setMeasurements([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setMeasurements([]);
        setLoading(false);
      })
  }

  const loadMeasurementParameters = (patientId) => {
    ApiCalls.getdetails("patientId/" + patientId + "/getmeasurementparameter")
      .then((res) => {
        if (res.data.measure !== undefined) {
          setMeasureParameters(res.data.measure[0].paramName)
        }
      })
  }

  const loadPatientHistory = (patientId) => {
    ApiCalls.getdetails("patientId/" + patientId + "/bedById")
      .then((response) => {
        if (response.data.beds != undefined) {
          setPatientHistory(response.data.beds);
        } else {
          setPatientHistory([]);
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const loadAllPatientImages = () => {
    setLoading(true);
    let tenantId = secureLocalStorage.getItem("tenantId");
    let companyId = secureLocalStorage.getItem("compId");
    let physicianId = "";
    const rolename = secureLocalStorage.getItem("rolename");
    const patientid = secureLocalStorage.getItem("PatientToView");

    if (rolename === "Tenant") {
      tenantId = secureLocalStorage.getItem("UUID");
      companyId = "0";
      physicianId = "0";
    } else if (rolename === "Company") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = secureLocalStorage.getItem("UUID");
      physicianId = "0";
    } else if (rolename === "Physician") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = secureLocalStorage.getItem("compId");
      physicianId = secureLocalStorage.getItem("UUID");
    }

    ApiCalls.getdetails(
      `tenant/${tenantId}/company/${companyId}/physician/${physicianId}/patient/${patientid}/displayUploadedImages`
    )
      .then((response) => {
        if (response.data.diagnosticImage) {
          // Ensure the correct field is being used
          setPatients(response.data.diagnosticImage);

          const uniqueImageTypes = [...new Set(response.data.diagnosticImage.map(img => img.imageType))];
          const uniqueScanTypes = [...new Set(response.data.diagnosticImage.map(img => img.scanType))];
          setImageTypes(uniqueImageTypes);
          setScanTypes(uniqueScanTypes);
        } else {
          setPatients([]);
        }
        setLoading(false);

        // Filter and load DICOM files
        const dicomFiles = response.data.diagnosticImage.filter(
          (img) => img.imageType === "DICOM"
        );
        loadDicomImages(dicomFiles);
      })
      .catch((error) => {
        console.log("API call error:", error);
        setLoading(false);
        setPatients([]);
      });
  };

  const loadDicomImages = (dicomFiles) => {
    const promises = dicomFiles.map((file) =>
      ApiCalls.viewFile("viewDicomImage", { filePath: file.filePath + "/" + file.fileName })
        .then(response => ({
          imageBlob: response.data instanceof Blob ? response.data : null,
          filePath: file.filePath,  // Include filePath directly
          fileName: file.fileName,  // Include fileName directly
          details: {
            scanType: file.scanType,
            imageId: file.imageId,
            bodyPart: file.bodySite,
            description: file.imageDescription,
            dateAndTime: file.scanDate,
            studyInstanceUID: file.studyInstanceUID,
            seriesInstanceUID: file.seriesInstanceUID,
            modality: file.modality,
            institutionName: file.institutionName,
            referringPhysicianName: file.referringPhysicianName,
            patientAge: file.patientAge,
            patientWeight: file.patientWeight,
            windowCenter: file.windowCenter,
            windowWidth: file.windowWidth,
            pixelRepresentation: file.pixelRepresentation,
            series: file.series,
            instanceNo: file.instanceNo,
            imgPosition: file.imgPosition,
            imgOrientation: file.imgOrientation,
            sliceThickness: file.sliceThickness,
            pixelSpacing: file.pixelSpacing,
            bitsAllocated: file.bitsAllocated,
            bitsStored: file.bitsStored,
            highBit: file.highBit,
            interpretation: file.interpretation,
            manufacturer: file.manufacturer,
            stationName: file.stationName,
            protocolName: file.protocolName,
            scanningSequence: file.scanningSequence,
            filePath: file.filePath + "/" + file.fileName
          }
        }))
    );

    Promise.all(promises)
      .then((dicomData) => {
        const validDicomData = dicomData.filter(data => data.imageBlob !== null);
        setSeriesImages(validDicomData);
      })
      .catch((error) => {
        console.error("Error loading DICOM images:", error);
      });
  };

  useEffect(() => {
    // Fetch the patient ID from secure local storage when the component mounts
    const patientid = secureLocalStorage.getItem("PatientToView");
    setPatientId(patientid);
  }, []);

  useEffect(() => {
    // Fetch parameters and other patient-related data when patientId is set
    if (patientId) {
      getParameterName(patientId);
      loadPatientDetailsById(patientId);
      loadPhysicianDetailsById(patientId);
      loadDeviceDetailsById(patientId);
      loadMeasurementParameters(patientId);
      loadMeasurementDetailsById(patientId);
      fetchImages(patientId);
      loadPatientHistory(patientId);
      loadAllPatientImages();
    }
  }, [patientId]);

  useEffect(() => {
    // Trigger chartMeasurements API call when selectedParamFilter, selectedDateFilter, or patientId changes
    if (selectedParamFilter && patientId && tabValue === (secureLocalStorage.getItem("rolename") === "Physician" ? 2 : 3)) {
      chartMeasurements(patientId, selectedParamFilter.label, selectedDateFilter.value, selectedDurationFilter.value);
    }
  }, [selectedParamFilter, selectedDateFilter, selectedDurationFilter, patientId, tabValue]);

  const getParameterName = async (patientId) => {
    try {
      const res = await ApiCalls.getdetails(`patientId/${patientId}/getmeasurementparameter`);
      if (res.data.measure && res.data.measure.length > 0) {
        const firstParam = res.data.measure[0];
        const options = res.data.measure.map(d => ({
          label: d.paramName,
          value: d.paramid
        }));

        setParameters(options);
        setSelectedParamFilter(options[0]);
      }
    } catch (err) {
      console.error('Error fetching parameters:', err);
    }
  };

  const chartMeasurements = async (patientId, parameterName, dateFilter, durationFilter) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    let labelData = [];
    let chartData = [];

    try {
      const res = await ApiCalls.getdetails(`patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/${durationFilter}`);
      if (res.data.measurement) {
        for (const dataObj of res.data.measurement) {
          const dateStr = dataObj.measTimeStamp;

          // Manually parse the date string
          const [dayName, monthName, day, time, tz, year] = dateStr.split(' ');

          const monthMap = {
            Jan: 0,
            Feb: 1,
            Mar: 2,
            Apr: 3,
            May: 4,
            Jun: 5,
            Jul: 6,
            Aug: 7,
            Sep: 8,
            Oct: 9,
            Nov: 10,
            Dec: 11,
          };

          const [hours24, minutes, seconds] = time.split(':').map(Number);

          // Create a Date object
          const localDate = new Date(
            Date.UTC(
              parseInt(year),
              monthMap[monthName],
              parseInt(day),
              hours24 - 5, // Adjust for IST (UTC+5:30)
              minutes - 30,
              seconds
            )
          );

          // Extract components for formatting
          const month = String(localDate.getUTCMonth() + 1).padStart(2, '0');
          const date = String(localDate.getUTCDate()).padStart(2, '0');
          const fullYear = localDate.getUTCFullYear();

          let hours12 = localDate.getUTCHours() % 12;
          hours12 = hours12 === 0 ? 12 : hours12; // Convert 0 to 12 for 12-hour format
          const utcMinutes = String(localDate.getUTCMinutes()).padStart(2, '0');
          const utcSeconds = String(localDate.getUTCSeconds()).padStart(2, '0');
          const milliseconds = String(localDate.getUTCMilliseconds()).padStart(3, '0');
          const ampm = localDate.getUTCHours() >= 12 ? 'PM' : 'AM';

          // Format based on dateFilter
          let formattedDateStr;
          if (dateFilter === 'today') {
            formattedDateStr = `${hours12}:${utcMinutes}:${utcSeconds} ${ampm}`;
          } else {
            formattedDateStr = `${month}-${date}-${fullYear}, ${hours12}:${utcMinutes}:${utcSeconds} ${ampm}`;
          }
          labelData.push(formattedDateStr);
          chartData.push(parseFloat(dataObj.readingValues));
        }
      }
    } catch (err) {
      console.error('Error fetching measurements:', err);
    }

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(canvas, {
      type: 'line',
      data: {
        labels: labelData.length > 0 ? labelData : ['No data available'],
        datasets: chartData.length > 0 ? [{
          label: `${parameterName} () Reading Level`,
          data: chartData,
          borderColor: '#6ff9ff',
          borderWidth: 1.5,
          tension: 0.5,
          pointStyle: 'circle',
          pointRadius: 0,
        }] : [{
          label: `${parameterName} () Reading Level`,
          data: [0],
          borderColor: '#6ff9ff',
          borderWidth: 1.5,
          tension: 0.5,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            reverse: true, // This will reverse the x-axis, making the graph draw from right to left
          },
          y: {
            beginAtZero: true, // This makes the y-axis start from 0
          },
        },
      },
    });
  };

  const handleOpenParamFilters = (e) => {
    setOpenParamFilter(e.currentTarget);
  };

  const handleCloseParamFilters = () => {
    setOpenParamFilter(null);
  };

  const handleOpenDurationFilters = (e) => {
    setOpenDurationFilter(e.currentTarget);
  };

  const handleCloseDurationFilters = () => {
    setOpenDurationFilter(null);
  };

  const handleDurationFilterChange = (filter) => {
    setSelectedDurationFilter(filter);
    handleCloseDurationFilters();
  };

  const handleOpenDateFilters = (e) => {
    setOpenDateFilter(e.currentTarget);
  };

  const handleCloseDateFilters = () => {
    setOpenDateFilter(null);
  };

  const handleDateFilterChange = (filter) => {
    setSelectedDateFilter(filter);
    handleCloseDateFilters();
  };

  const handleParamFilterChange = (filter) => {
    setSelectedParamFilter(filter);
    handleCloseParamFilters();
  };

  const fetchImages = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/downloadFilesByPatientId")
      .then((response) => {
        if (response.data != undefined) {
          setImages(response.data);
        }
      })
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  }

  const handleUploadClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPatient(null);
  };

  const handleOpenDiComViewer = () => {
    setOpenDiComViewer(true);
  };

  const handleCloseDiComViewer = () => {
    setOpenDiComViewer(false);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale * 1.2);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale / 1.2);
  };

  const handleRotateLeft = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const handleRotateRight = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const handleReset = () => {
    setScale(1);
    setRotation(0);
    setFlipHorizontal(false);
    setFlipVertical(false);
  };

  const handleFlipHorizontal = () => {
    setFlipHorizontal((prevFlip) => !prevFlip);
  };

  const handleFlipVertical = () => {
    setFlipVertical((prevFlip) => !prevFlip);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!imageType) {
        setImageTypeError(true);
        setError("Please select an image type.");
        return;
      }
      setFileLoading(true);
      // Create a FormData object to hold the file data and other information
      const formData = new FormData();
      formData.append("file", file);
      formData.append("imageType", imageType);

      // Send the form data to the server
      ApiCalls.storeFile("uploadFileToServerFromApp", formData)
        .then((response) => {
          const { status } = response.data;
          if (status.message === "Success") {

            // Extract the filePath from the response
            const filePath = status.filePath;
            setFilePath(filePath);
            let viewPath = "";

            // Determine the API path based on imageType
            if (imageType === "Standard") {
              viewPath = "viewFile";
            } else if (imageType === "DICOM") {
              viewPath = "viewDicomImage";
            }
            // View the file using the appropriate endpoint
            ApiCalls.viewFile(viewPath, { filePath }, { responseType: "blob" })
              .then((response) => {
                // Convert the response to a Blob and create a URL
                const blob = new Blob([response.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                setImage(url); // Set the image URL to display
                setSelectedFile(file); // Optional: store the file if needed
                setFileLoading(false);
                // If the imageType is "DICOM", call readDicomDetails
                if (imageType === "DICOM") {
                  readDicomDetails(filePath);
                }
              })
              .catch((error) => {
                console.error(`Error in ${viewPath} API:`, error);
                setFileLoading(false);
                // Handle error, e.g., show an error message
              });
          } else {
            handleFileUploadError(status.details);
          }
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          // Handle error, e.g., show an error message
        });
    }
  };

  const handleFileUploadError = (details) => {
    setFileLoading(false);
    if (details === "File already exists") {
      swal("File Already Exists", { icon: "info" });
    } else {
      console.error('Upload failed:', details);
      setError('Upload failed: ' + details);
    }
  };

  const readDicomDetails = (filePath) => {
    // API call to read DICOM details
    ApiCalls.getdetails(`readdicom?filePath=${encodeURIComponent(filePath)}`)
      .then((response) => {
        // Update the state with the data from the API
        setHmrNo(response.data.patientId || "");
        setName(response.data.firstName || "");
        const dobDateFormatted = response.data.dobDate ? moment(response.data.dobDate).format("YYYY-MM-DD") : '';
        setDob(dobDateFormatted);
        // Mapping gender correctly when setting it from response
        const genderValue = response.data.gender === "M" ? "Male" : response.data.gender === "F" ? "Female" : response.data.gender;
        setGender(genderValue || ''); // Set the gender value
        setScanType(response.data.scanType || "");
        setImageDescription(response.data.imageDescription || "");
        setBodySite(response.data.bodySite || "");

        // Explicitly set imageType to "DICOM"
        setImageType("DICOM");

        // Filter out the already handled fields
        const {
          patientId,
          firstName,
          dobDate, // This is the original dobDate from the response
          gender,
          scanType,
          imageDescription,
          bodySite,
          ...otherDetails
        } = response.data;

        // Set the remaining details to DICOMImageDetails
        setDICOMImageDetails(otherDetails);
      })
      .catch((error) => {
        console.error("Error reading DICOM details:", error);
        setError('Error reading DICOM details.');
      });
  };

  const handleRadioChange = (event) => {
    setImageType(event.target.value);
    setError(""); // Clear error when an image type is selected
  };

  const handleCancel = () => {
    // Implement cancel functionality here
    setOpenDialog(false);
    setSelectedFile(null);
    setFilePath("")
    setPatientIdError(false);
    setNameError(false);
    setDobError(false);
    setImageTypeError(false);
    setFilePathError(false);
  };

  const handleSaveDetails = () => {
    // Collect validation errors
    const errors = {};

    // Check if patientId is missing and should show an error for Standard images
    if (!patientId && imageType !== "DICOM") {
      errors.patientId = true;
    }

    // Check if name is missing and should show an error for Standard images
    if (!name && imageType !== "DICOM") {
      errors.name = true;
    }

    // Check if dob is missing and should show an error for Standard images
    if (!dob && imageType !== "DICOM") {
      errors.dob = true;
    }

    // Always check imageType
    if (!imageType) {
      errors.imageType = true;
      setError('Please select an image type.');
    }

    // Check if filePath or selectedFile is missing and show an error
    if (!filePath && !selectedFile) {
      errors.filePath = true;
    }

    // Check if filePath is missing and should show an error for Standard images
    if (!filePath && imageType !== "DICOM") {
      errors.filePath = true;
    }

    // Display errors if any
    if (Object.keys(errors).length > 0) {
      // Set all errors at once
      setPatientIdError(errors.patientId || false);
      setNameError(errors.name || false);
      setDobError(errors.dob || false);
      setImageTypeError(errors.imageType || false);
      setFilePathError(errors.filePath || false);
      return; // Stop further execution
    } else {
      // If DICOM image's patient ID is different from selected patient ID
      if (imageType === "DICOM" && hmrNo && patientDetails.patientId !== hmrNo) {
        // Show a confirmation dialog asking if the user wants to proceed with the new details
        swal({
          title: `Different ${secureLocalStorage.getItem("PatientName")}`,
          text: `The DICOM image belongs to a different ${secureLocalStorage.getItem("PatientName")}. Do you want to proceed with the new ${secureLocalStorage.getItem("PatientName")} details?`,
          icon: "info",
          buttons: ["Cancel", "Proceed"],
        }).then((willProceed) => {
          if (willProceed) {
            // If user proceeds, update the fields with new details fetched from the DICOM

            let tenantId = secureLocalStorage.getItem("tenantId");
            let companyId = secureLocalStorage.getItem("compId");
            let physicianId = "";
            const rolename = secureLocalStorage.getItem("rolename");

            if (rolename === "Tenant") {
              tenantId = secureLocalStorage.getItem("UUID");
              companyId = "0";
              physicianId = "0";
            } else if (rolename === "Company") {
              tenantId = secureLocalStorage.getItem("tenantId");
              companyId = secureLocalStorage.getItem("UUID");
              physicianId = "0";
            } else if (rolename === "Physician") {
              tenantId = secureLocalStorage.getItem("tenantId");
              companyId = secureLocalStorage.getItem("compId");
              physicianId = secureLocalStorage.getItem("UUID");
            }

            // Define the data to be sent in the POST request, including DICOMImageDetails
            const requestBody = {
              tenantId: tenantId,
              companyId: companyId,
              physicianId: physicianId,
              patientId: hmrNo,
              firstName: name,
              dob: dob,
              imageType: imageType,
              scanType: scanType,
              filePath: filePath,
              imageDescription: imageDescription,
              bodySite: bodySite,
              ...DICOMImageDetails,  // Spread DICOMImageDetails into the request body
              imageType: imageType,
            };

            ApiCalls.register("saveImages", requestBody)
              .then((response) => {
                if (response.data.status.message === "Success") {
                  swal(
                    `${secureLocalStorage.getItem(
                      "PatientName"
                    )} Image Uploaded Successfully`,
                    {
                      icon: "success",
                    }
                  );
                  handleCancel();
                  loadAllPatientImages();
                  loadPatientDetailsById(patientId);
                } else {
                  console.log(
                    `${secureLocalStorage.getItem("PatientName")} Image Upload Failed`
                  );
                }
              })
              .catch((err) => {
                console.log(err);
              });

          } else {
            // If user cancels, do nothing, keep the existing patient details
            return;
          }
        });
      } else {
        // No mismatch, proceed with saving details
        // Implement save details functionality here

        let tenantId = secureLocalStorage.getItem("tenantId");
        let companyId = secureLocalStorage.getItem("compId");
        let physicianId = "";
        const rolename = secureLocalStorage.getItem("rolename");

        if (rolename === "Tenant") {
          tenantId = secureLocalStorage.getItem("UUID");
          companyId = "0";
          physicianId = "0";
        } else if (rolename === "Company") {
          tenantId = secureLocalStorage.getItem("tenantId");
          companyId = secureLocalStorage.getItem("UUID");
          physicianId = "0";
        } else if (rolename === "Physician") {
          tenantId = secureLocalStorage.getItem("tenantId");
          companyId = secureLocalStorage.getItem("compId");
          physicianId = secureLocalStorage.getItem("UUID");
        }

        // Define the data to be sent in the POST request
        const requestBody = {
          tenantId: tenantId,
          companyId: companyId,
          physicianId: physicianId,
          patientId: patientDetails.patientId,
          firstName: name,
          dob: dob,
          imageType: imageType,
          scanType: scanType,
          filePath: filePath,
          imageDescription: imageDescription,
          bodySite: bodySite,
        };

        // Make the POST request to the API

        ApiCalls.register("saveImages", requestBody)
          .then((response) => {
            if (response.data.status.message === "Success") {
              swal(
                `${secureLocalStorage.getItem(
                  "PatientName"
                )} Image Uploaded Successfully`,
                {
                  icon: "success",
                }
              );
              handleCancel();
              loadAllPatientImages();
              loadPatientDetailsById(patientId);
            } else {
              console.log(
                `${secureLocalStorage.getItem("PatientName")} Image Upload Failed`
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleViewImage = (imageType, filePath, fileName) => {
    if (imageType === "Standard") {
      setOpenImageViewDialog(true);
      setImageType(imageType);
      viewStandardFile(filePath + "/" + fileName);
    } else if (imageType === "DICOM") {
      setOpenImageViewDialog(true);
      setImageType(imageType);
      viewDicomFile(filePath + "/" + fileName);
    } else if (imageType === "Other") {
      // For other file types like PDFs, DOCX, etc.
      setOpenDocViewDialog(true);
      setImageType(imageType);
      viewOtherFile(filePath + "/" + fileName);
    }
  };

  const viewStandardFile = (filePath) => {
    ApiCalls.viewFile("viewFile", { filePath })
      .then((response) => {
        if (response.data instanceof Blob) {
          const url = window.URL.createObjectURL(response.data);
          setImageURL(url); // Set the URL to display the image

          // Optionally, you can also read the Blob and convert it to Base64 if needed
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Image = reader.result;
            // You can use Base64 if needed, otherwise, the object URL should work
          };
          reader.readAsDataURL(response.data);
        } else {
          console.error("Unexpected response data type:", typeof response.data);
        }
      })
      .catch((error) => {
        console.error("Error in viewFile API:", error);
        // Handle error, e.g., show an error message
      });
  };

  const viewDicomFile = (filePath) => {
    ApiCalls.viewFile("viewDicomImage", { filePath })
      .then((response) => {
        if (response.data instanceof Blob) {
          setDicomFile(response.data); // Set the Blob to display in DicomViewer
        } else {
          console.error("Unexpected response data type:", typeof response.data);
        }
      })
      .catch((error) => {
        console.error("Error in viewDicomImage API:", error);
        // Handle error, e.g., show an error message
      });
  };

  const viewOtherFile = (filePath) => {
    ApiCalls.viewFile("viewUploadedFile", { filePath }, { responseType: 'blob' }) // Ensure responseType is 'blob'
      .then((response) => {
        if (response.data instanceof Blob) {
          const fileURL = window.URL.createObjectURL(response.data);

          // Check if the file is a PDF and display it
          if (response.data.type === 'application/pdf') {
            setDocURL(fileURL);  // Set the URL for PDF display
          } else {
            window.open(fileURL, '_blank');  // For other types, open in new tab
          }
        }
      })
      .catch((error) => {
        console.error("Error viewing file: ", error);
      });
  };

  const handleCloseImageViewDialog = () => {
    setOpenImageViewDialog(false);
    setOpenDocViewDialog(false);
    setImageURL("");
    setDocURL("");
    setDicomFile(null);
  };

  const filteredPatients = patients.filter((patient) => {
    const { date, imageType, scanType } = filters;
    const patientDate = moment(patient.scanDate).startOf('day');
    const filterDate = date ? moment(date).startOf('day') : null;

    return (
      (!filterDate || patientDate.isSame(filterDate)) &&
      (!imageType || patient.imageType.toLowerCase() === imageType.toLowerCase()) &&
      (!scanType || patient.scanType === scanType)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleDateChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOpenDialog = (patient) => {
    // Logic to open the dialog and pass patient data
    setDialogData(patient); // Example: set patient data to state
    setOpenImageDetails(true); // Open the dialog
  };

  const onImageDetailsClose = () => {
    setOpenImageDetails(false); // Close the dialog
  }

  return (
    <div style={{ display: "flex" }}>
      <Preview />
      <Container sx={{ marginTop: 10 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard">
              <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                Home
              </Button>
            </Link>

            <Link to="/managepatient">
              <Button variant="text" startIcon={<Icon icon={'material-symbols:recent-patient-rounded'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                {"Manage " + secureLocalStorage.getItem("PatientName")}
              </Button>
            </Link>

            <StyledBreadcrumb label={secureLocalStorage.getItem("PatientName") + " View"} />
          </Breadcrumbs>
        </Stack>

        <Card>
          <CardHeader
            title={<Tabs
              value={tabValue}
              onChange={handleChangeTab}
              onMouseDown={handleMouseDown}
              aria-label="icon label tabs example"
            >
              <Tab
                icon={<Icon icon={"solar:user-id-bold"} width="30" />}
                label="General Details"
                sx={{ fontSize: 18 }}
              />
              {secureLocalStorage.getItem("rolename") !== "Physician" && (
                <Tab
                  icon={<Icon icon={"fa6-solid:user-doctor"} width="30" />}
                  label={`${secureLocalStorage.getItem("PhysicianName")} Details`}
                  wrapped
                  sx={{ fontSize: 18 }}
                />
              )}
              <Tab
                icon={<Icon icon={"tabler:devices-pin"} width="30" />}
                label={`${secureLocalStorage.getItem("DeviceName")} Details`}
                sx={{ fontSize: 18 }}
              />
              <Tab
                icon={<Icon icon={"fa-solid:chart-bar"} width="30" />}
                label="Measurement Details"
                wrapped
                sx={{ fontSize: 18 }}
              />
              <Tab
                icon={<Icon icon={"fluent:history-16-filled"} width="30" />}
                label="History"
                wrapped
                sx={{ fontSize: 18 }}
              />
              {secureLocalStorage.getItem("rolename") === "Physician" && (
                <Tab
                  icon={<Icon icon={"material-symbols:view-list"} width="30" />}
                  label="View Diagnostics"
                  wrapped
                  sx={{ fontSize: 18 }}
                />
              )}
            </Tabs>}
          />


          <CardContent>
            {/* General Details Tab */}
            <TabPanel value={tabValue} index={0}>
              {loadPatientDetails ? ( // Display loading component when loadPatientDetails is true
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      <Skeleton
                        variant="rounded"
                        width={200}
                        height={200}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <Box sx={{ mb: 2 }}>
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="60%" />
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Skeleton variant="text" width="50%" />
                        <Skeleton variant="text" width="70%" />
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Skeleton variant="text" width="40%" />
                        <Skeleton variant="text" width="60%" />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>

              ) : (
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4} >
                      <Avatar
                        variant='rounded'
                        alt={patientDetails.firstName + ' ' + patientDetails.lastName}
                        src={patientDetails.image}
                        sx={{ width: 200, height: 200, objectFit: "contain" }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} >
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h5" sx={{ color: 'text.primary' }}>
                          {(patientDetails.firstName ? patientDetails.firstName : '') +
                            (patientDetails.lastName ? ' ' + patientDetails.lastName : '') || '--'}
                        </Typography>

                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          {patientDetails.email && patientDetails.email.toLowerCase() !== 'null' ? patientDetails.email : ''}
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          Gender
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                          {patientDetails.gender}
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          DOB
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                          {patientDetails.dob && patientDetails.dob.split(' ')[0]}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                            Phone
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            {patientDetails.phone}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Location
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                            {patientDetails.address && Object.keys(patientDetails.address).length > 0 ? (
                              <>
                                {patientDetails.address.city && patientDetails.address.state && patientDetails.address.country ? (
                                  ` ${patientDetails.address.state}, ${patientDetails.address.country}`
                                ) : (
                                  <>
                                    {patientDetails.address.city && patientDetails.address.state ? (
                                      `${patientDetails.address.city}, ${patientDetails.address.state}`
                                    ) : (
                                      <>
                                        {patientDetails.address.city || ""}
                                        {patientDetails.address.city && patientDetails.address.state && patientDetails.address.country ? ", " : ""}
                                        {patientDetails.address.state || ""}
                                        {patientDetails.address.state && patientDetails.address.country ? ", " : ""}
                                        {patientDetails.address.country || ""}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              "Not Provided"
                            )}
                          </Typography>
                        </Box>
                      </Box>

                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </TabPanel>

            {/* Physician Details Tab */}
            {secureLocalStorage.getItem("rolename") !== "Physician" && (
              <TabPanel value={tabValue} index={1}>
                {/* Physician Details Content */}
                {physicianDetails.length > 0 ? <Grid container spacing={3}>
                  {physicianDetails.map((physician) => (
                    <Grid item xs={12} sm={6} md={6} >
                      <Card key={physician.physicianId} sx={{
                        boxShadow: 3,
                        px: 2,
                        py: 3,
                        minHeight: 200,
                        backgroundColor: "#F4F6F8",
                      }}>
                        <CardHeader
                          avatar={
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                              <Avatar
                                variant='rounded'
                                alt={physician.firstName + ' ' + physician.lastName}
                                src={physician.image}
                                sx={{ width: 120, height: 120, objectFit: "contain" }}
                              />
                              <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Name :
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {physician.firstName + ' ' + physician.lastName}
                                  </Typography>
                                </Box>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Email
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {physician.email && physician.email.toLowerCase() !== 'null' ? physician.email : ''}
                                  </Typography>
                                </Box>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Phone
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {physician.phone}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          }
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid> :
                  <center>
                    No {secureLocalStorage.getItem("PhysicianName")} Assigned
                  </center>
                }
              </TabPanel>
            )}

            {/* Device Details Tab */}
            <TabPanel
              value={tabValue}
              index={
                secureLocalStorage.getItem("rolename") === "Physician" ? 1 : 2
              }
            >
              {/* Device Details Content */}
              {deviceDetails.length > 0 ?
                <Grid container spacing={3}>
                  {deviceDetails.map((device) => (
                    <Grid item xs={12} sm={6} md={6} >
                      <Card key={device.deviceId} sx={{
                        boxShadow: 3,
                        px: 2,
                        py: 3,
                        minHeight: 200,
                        backgroundColor: "#F4F6F8",
                      }}>
                        <CardHeader
                          avatar={
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                              <Avatar
                                variant='rounded'
                                alt={device.devicename}
                                src={device.deviceimageName ? device.deviceimageName : DeviceImg}
                                sx={{ width: 120, height: 120, objectFit: "contain" }}
                              />
                              <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Device Name
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {device.devicename}
                                  </Typography>
                                </Box>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Device Macid
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {device.decmacid}
                                  </Typography>
                                </Box>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Model Name
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {device.modelName === undefined ? " " : device.modelName}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          }
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid> :
                <center>
                  No {secureLocalStorage.getItem("DeviceName")} Assigned
                </center>
              }
            </TabPanel>

            {/* Measurement Details Tab - Show for Both Roles */}
            <TabPanel
              value={tabValue}
              index={
                secureLocalStorage.getItem("rolename") === "Physician" ? 2 : 3
              }
            >
              {/* Measurement Details Content */}
              <Grid item xs={12} sm={12} md={12} >
                <Card
                  sx={{
                    px: 5,
                    boxShadow: 5,
                    textAlign: 'left',
                  }}
                >
                  <CardHeader title="Graphical Display" action={
                    <>
                      <Tooltip title="Filter Parameter">
                        <Button color='primary' variant='contained' onClick={handleOpenParamFilters} endIcon={<Iconify icon="material-symbols:filter-alt" />}>
                          {selectedParamFilter.label}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Filter Date">
                        <Button className='ml-2' color='primary' variant='contained' onClick={handleOpenDateFilters} endIcon={<Iconify icon="material-symbols:filter-list-rounded" />}>
                          {selectedDateFilter.label}
                        </Button>
                      </Tooltip>
                      {selectedDateFilter.value === "today" && (
                        <Tooltip title="Filter Hours">
                          <Button
                            className="ml-2"
                            color="primary"
                            variant="contained"
                            onClick={handleOpenDurationFilters}
                            endIcon={<Iconify icon="ph:timer-fill" />}
                          >
                            {selectedDurationFilter.label}
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  } />
                  <CardContent>
                    <Box sx={{ p: 3, pb: 1, height: 350, borderRadius: "10px" }} dir="ltr" style={{ backgroundColor: '#27293d' }}>
                      <canvas ref={canvasRef} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Popover
                open={Boolean(openDurationFilter)}
                anchorEl={openDurationFilter}
                onClose={handleCloseDurationFilters}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 1,
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                      px: 1,
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <Stack spacing={0.75}>
                  {durationFilterOptions.map((option) => (
                    <MenuItem key={option.value} selected={option.value === selectedDurationFilter.value} onClick={() => handleDurationFilterChange(option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Stack>
              </Popover>

              <Popover
                open={Boolean(openDateFilter)}
                anchorEl={openDateFilter}
                onClose={handleCloseDateFilters}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 1,
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                      px: 1,
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <Stack spacing={0.75}>
                  {filterOptions.map((option) => (
                    <MenuItem key={option.value} selected={option.value === selectedDateFilter.value} onClick={() => handleDateFilterChange(option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Stack>
              </Popover>

              <Popover
                open={Boolean(openParamFilter)}
                anchorEl={openParamFilter}
                onClose={handleCloseParamFilters}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 1,
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                      px: 1,
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <Stack spacing={0.75}>
                  {parameters.map((option) => (
                    <MenuItem key={option.value} selected={option.value === selectedParamFilter.value} onClick={() => handleParamFilterChange(option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Stack>
              </Popover>

              <Grid item xs={12} sm={12} md={12} sx={{
                mt: 2
              }} >
                <MeasurementList
                  title="Measurement List"
                  measurements={measurements}
                  setMeasurements={setMeasurements}
                  patientId={patientId}
                  loading={loading}
                  setLoading={setLoading}
                  onRefreshGraph={() => chartMeasurements(patientId, selectedParamFilter.label, selectedDateFilter.value, selectedDurationFilter.value)} // Pass the chartMeasurements function
                />
              </Grid>
            </TabPanel>

            {/* History Tab */}
            <TabPanel
              value={tabValue}
              index={
                secureLocalStorage.getItem("rolename") === "Physician"
                  ? 3
                  : 4
              }
            >
              {/* History Content */}
              {patientHistory.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Ward No</TableCell>
                        <TableCell>Bed No</TableCell>
                        <TableCell>Admitted On</TableCell>
                        <TableCell>Discharged On</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patientHistory.map((patient) => (
                        <TableRow key={`${patient.bedId}-${patient.mapStatus}`}>
                          <TableCell>
                            {patient.mapStatus === 'Admitted' ? (
                              <Avatar sx={{ bgcolor: (theme) => theme.palette["success"].main }}>
                                <Icon icon={'material-symbols:inpatient-rounded'} />
                              </Avatar>
                            ) : (
                              <Avatar sx={{ bgcolor: (theme) => theme.palette["error"].main }}>
                                <Icon icon={'material-symbols:outpatient-rounded'} />
                              </Avatar>
                            )}
                            <Typography variant="body2" sx={{ marginTop: 1, color: patient.mapStatus === 'Admitted' ? (theme) => theme.palette["success"].darker : (theme) => theme.palette["error"].darker }}>
                              {patient.mapStatus === 'Admitted' ? "Admitted" : "Discharged"}
                            </Typography>
                          </TableCell>
                          <TableCell>{patient.wardNo}</TableCell>
                          <TableCell>{patient.bedNo}</TableCell>
                          <TableCell>
                            {new Date(Number(patient.admittedTime)).toLocaleString('en-US', {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            }).replace(",", "")}
                          </TableCell>
                          <TableCell>
                            {patient.mapStatus === 'Discharged' && (
                              new Date(Number(patient.dischargeTime)).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              }).replace(",", "")
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>
                </TableContainer>
              ) : (
                <center>
                  No History
                </center>
              )}
            </TabPanel>

            {/* View Diagnostics Tab (for Physicians only) */}
            {secureLocalStorage.getItem("rolename") === "Physician" && (
              <TabPanel value={tabValue} index={4}>
                {/* Diagnostics Content */}
                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h4" gutterBottom>
                        {"Image List"}
                      </Typography>
                    }
                    action={
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          startIcon={<Iconify icon="formkit:upload" />}
                          onMouseDown={handleMouseDown}
                          onClick={handleUploadClick}
                        >
                          Upload Image
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<Iconify icon="ic:baseline-view-list" />}
                          onClick={handleOpenDiComViewer}
                          onMouseDown={handleMouseDown}
                        >
                          DICOM Viewer
                        </Button>
                      </Stack>
                    }
                  />
                  <CardContent>
                    <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                      <TextField
                        id="date"
                        name="date"
                        label="Filter by Date"
                        type="date"
                        value={filters.date}
                        onChange={handleDateChange}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: maxDate,
                        }}
                        sx={{ width: filters.date ? 170 : 150 }}
                        InputProps={{
                          endAdornment: filters.date && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear date"
                                onMouseDown={handleMouseDown}
                                onClick={() => handleDateChange({ target: { name: 'date', value: '' } })}
                                edge="end"
                              >
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        select
                        label="Filter ImageType"
                        name="imageType"
                        value={filters.imageType}
                        onChange={handleFilterChange}
                        sx={{ width: 159 }} // Adjust width as needed
                      >
                        <MenuItem value="">All</MenuItem>
                        {imageTypes.map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        label="Filter ScanType"
                        name="scanType"
                        value={filters.scanType}
                        onChange={handleFilterChange}
                        sx={{ width: 153 }} // Adjust width as needed
                      >
                        <MenuItem value="">All</MenuItem>
                        {scanTypes.map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>
                    {loading ? (
                      <center>
                        <CircularProgress />
                      </center>
                    ) : filteredPatients.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Date and Time</TableCell>
                              <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Image Type</TableCell>
                              <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Scan Type</TableCell>
                              <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Image Description</TableCell>
                              <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Body Part Scan</TableCell>
                              <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredPatients
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((patient, index) => (
                                <TableRow
                                  key={patient.imageId}
                                  sx={{
                                    backgroundColor: index % 2 === 0 ? "#f4f4f4" : "inherit",
                                  }}
                                >
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {moment(patient.scanDate).format("YYYY-MM-DD HH:mm") || "N/A"}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {patient.imageType || "N/A"}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {patient.scanType || "N/A"}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {patient.imageDescription || "N/A"}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {patient.bodySite || "N/A"}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {/* Button for viewing the image */}
                                    <Tooltip title="View Image">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onMouseDown={handleMouseDown}
                                        onClick={() => handleViewImage(patient.imageType, patient.filePath, patient.fileName)}
                                        sx={{ marginTop: 1, marginRight: 1 }} // Add spacing
                                        startIcon={<Visibility />} // Use the eye icon for view
                                      >
                                        View
                                      </Button>
                                    </Tooltip>

                                    {/* IconButton for viewing additional DICOM information */}
                                    {patient.imageType === "DICOM" && (
                                      <Tooltip title="More Info">
                                        <IconButton
                                          color="primary"
                                          size="small"
                                          onMouseDown={handleMouseDown}
                                          onClick={() => handleOpenDialog(patient)}
                                          sx={{ marginTop: 1, marginLeft: 1 }} // Add spacing
                                        >
                                          <Info />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          sx={{
                            '.MuiButtonBase-root': {
                              '&:focus': {
                                outline: 'none',
                              },
                              '&:active': {
                                outline: 'none',
                                boxShadow: 'none',
                              },
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            },
                          }}
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={filteredPatients.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableContainer>

                    ) : (
                      <center>No Details Found</center>
                    )}
                  </CardContent>
                </Card>
              </TabPanel>
            )}
          </CardContent>

          <Dialog
            fullScreen={fullScreen}
            maxWidth="md"
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Image Details"}
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f0f0f0",
                }}
              >
                <Paper
                  elevation={3}
                  style={{ padding: 20, maxWidth: "800px", width: "100%" }}
                >
                  <Typography variant="h6" gutterBottom>
                    Image Type
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        value={imageType}
                        onChange={handleRadioChange}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <FormControlLabel
                            value="Standard"
                            control={<Radio />}
                            label="Standard"
                          />
                          <FormControlLabel
                            value="DICOM"
                            control={<Radio />}
                            label="DICOM"
                          />
                        </div>
                      </RadioGroup>
                      {imageTypeError && (
                        <Typography color="error" variant="body2">
                          {error}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <TextField
                        name="patientId"
                        label={
                          <span>
                            {secureLocalStorage.getItem("PatientName") + " Id"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        value={hmrNo}
                        // onChange={handleInputChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          style: { height: "50px" },
                          readOnly: true,
                        }}
                        style={{ marginBottom: "22px" }}
                        error={patientIdError}
                        helperText={
                          patientIdError &&
                          `${secureLocalStorage.getItem(
                            "PatientName"
                          )} Id is required`
                        }
                      />
                      <TextField
                        name="name"
                        label={
                          <span>
                            {secureLocalStorage.getItem("PatientName") +
                              " Name"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        value={name}
                        // onChange={handleInputChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          style: { height: "50px" },
                          readOnly: true,
                        }}
                        style={{ marginBottom: "22px" }}
                        error={nameError}
                        helperText={
                          nameError &&
                          `${secureLocalStorage.getItem(
                            "PatientName"
                          )} Name is required`
                        }
                      />
                      <TextField
                        id="date"
                        name="dob"
                        label={
                          <span>
                            DOB <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        type="date"
                        value={dob}
                        // onChange={handleInputChange}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: maxDate,
                        }}
                        error={dobError}
                        helperText={dobError && `DOB Id is required`}
                      />
                      <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: "22px", mt: 2 }}
                      >
                        <InputLabel>Gender</InputLabel>
                        <Select
                          name="gender"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          label="Gender"
                          inputProps={{
                            readOnly: true,
                          }}
                        >
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {fileLoading ? (
                        <Avatar
                          alt="Image"
                          variant="rounded"
                          sx={{
                            width: 350,
                            height: 300,
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                              cursor: 'pointer',
                              transform: 'scale(1.1)',
                            },
                          }}
                        >
                          <Icon icon="eos-icons:bubble-loading" color="#4481eb" width="80" />
                        </Avatar>
                      ) :
                        selectedFile && imageType === "DICOM" ? (
                          <DiComViewer file={selectedFile} />
                        ) : (
                          <Avatar
                            alt="Image"
                            variant="rounded"
                            src={
                              selectedFile
                                ? URL.createObjectURL(selectedFile)
                                : null
                            }
                            sx={{
                              width: 350,
                              height: 300,
                              transition: "all 0.2s ease-in-out",
                              "&:hover": {
                                cursor: "pointer",
                                transform: "scale(1.1)",
                              },
                            }}
                            onClick={() =>
                              document.getElementById("avatar-input").click()
                            }
                          >
                            <Icon
                              icon="flat-color-icons:add-image"
                              color="#4481eb"
                              width="80"
                            />
                            <input
                              type="file"
                              accept="image/*, .dcm"
                              id="avatar-input"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            {filePathError && (
                              <Typography color="error" variant="button">
                                Please select a file to upload.
                              </Typography>
                            )}
                          </Avatar>
                        )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSaveDetails}
                        onMouseDown={handleMouseDown}
                      >
                        Save Details
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={handleCancel}
                        onMouseDown={handleMouseDown}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </DialogContent>
          </Dialog>

          <ThemeProvider theme={darkTheme}>
            <Dialog
              fullScreen={docURL ? true : false} // Full screen for document viewer
              open={openDocViewDialog}
              aria-labelledby="responsive-dialog-title"
              sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <DialogTitle
                id="responsive-dialog-title"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">Document Viewer</Typography>
                <IconButton onClick={handleCloseImageViewDialog} aria-label="close">
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent
                sx={{
                  padding: 0,
                  height: '100%', // Ensure the iframe fills the dialog height
                }}
              >
                {docURL ? (
                  // Use an iframe to display the PDF or document
                  <iframe
                    src={docURL}
                    title="PDF Document"
                    width="100%"
                    height="100%"
                    style={{
                      border: 'none',
                      height: '100vh', // Full screen height
                      width: '100vw',  // Full screen width
                    }}
                  />
                ) : (
                  <center>
                    <Icon icon="eos-icons:three-dots-loading" width="100" height="100" />
                  </center>
                )}
              </DialogContent>
            </Dialog>
          </ThemeProvider>

          <ThemeProvider theme={darkTheme}>
            <Dialog
              fullScreen={false}
              maxWidth="lg"
              open={openImageViewDialog}
              aria-labelledby="responsive-dialog-title"
              sx={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <DialogTitle id="responsive-dialog-title" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6">
                  {"Image Viewer"}
                </Typography>
                <IconButton
                  onClick={handleCloseImageViewDialog}
                  onMouseDown={handleMouseDown}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {imageType === "DICOM" ? (
                  dicomFile ? (
                    <DicomViewer file={dicomFile} />
                  ) : (
                    <center>
                      <Icon icon="eos-icons:three-dots-loading" width="100" height="100" />
                    </center>
                  )
                ) : imageURL ? (
                  <img
                    src={imageURL}
                    alt="View"
                    style={{
                      width: "100%",
                      height: "auto",
                      minHeight: 500,
                      minWidth: 500,
                      maxWidth: 700,
                      maxHeight: 600,
                      transition: "all 0.2s ease-in-out",
                      cursor: "pointer",
                      transform: `
                                    rotate(${rotation}deg) 
                                    scale(${scale}) 
                                    scaleX(${flipHorizontal ? -1 : 1}) 
                                    scaleY(${flipVertical ? -1 : 1})
                                `,
                    }}
                    onError={(e) => {
                      console.log("Error loading image:", e);
                    }}
                  />
                ) : (
                  <center>
                    <CircularProgress />
                  </center>
                )}
              </DialogContent>
              <DialogActions
                sx={{
                  display: imageType === "Standard" ? "block" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "black",
                  py: 1,
                  width: "100%",
                  borderTop: "1px solid #333",
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleZoomIn}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'tabler:zoom-in'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Zoom In</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleZoomOut}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'tabler:zoom-out'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Zoom Out</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleRotateLeft}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'ic:outline-rotate-left'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Left</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleRotateRight}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'ic:outline-rotate-right'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Right</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleFlipHorizontal}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'mdi:flip-horizontal'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Flip Horizontal</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleFlipVertical}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'mdi:flip-vertical'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Flip Vertical</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleReset}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'bx:reset'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Reset View</Typography>
                  </Box>
                </Box>
              </DialogActions>
            </Dialog>
          </ThemeProvider>

          <Dialog
            open={openImageDetails}
            onClose={() => setOpenImageDetails(false)}
            maxWidth="md"
            fullWidth
            PaperProps={{ style: { height: dialogHeight } }} // Apply the responsive height
          >
            <DialogTitle>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">DICOM Image Details</Typography>
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={onImageDetailsClose}
                  onMouseDown={handleMouseDown}
                  aria-label="close"
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              <List>
                <Grid container spacing={2}>
                  {[
                    { label: 'Patient ID', value: dialogData.patientId },
                    { label: 'Patient Name', value: dialogData.firstName },
                    { label: 'Age', value: dialogData.patientAge },
                    { label: 'Weight', value: dialogData.patientWeight },
                    { label: 'Image Type', value: dialogData.imageType },
                    { label: 'Modality', value: dialogData.modality },
                    { label: 'Institution Name', value: dialogData.institutionName },
                    { label: 'Station Name', value: dialogData.stationName },
                    { label: 'Scan Date', value: new Date(dialogData.scanDate).toLocaleString() },
                    { label: 'Scan Type', value: dialogData.scanType },
                    { label: 'Body Site', value: dialogData.bodySite },
                    { label: 'Description', value: dialogData.imageDescription },
                    { label: 'Study Instance UID', value: dialogData.studyInstanceUID },
                    { label: 'Series Instance UID', value: dialogData.seriesInstanceUID },
                    { label: 'Protocol Name', value: dialogData.protocolName },
                    { label: 'Series Description', value: dialogData.seriesDescription },
                    { label: 'Image Orientation', value: dialogData.imgOrientation },
                    { label: 'Image Position', value: dialogData.imgPosition },
                    { label: 'Slice Thickness', value: dialogData.sliceThickness },
                    { label: 'Pixel Spacing', value: dialogData.pixelSpacing },
                    { label: 'Bits Allocated', value: dialogData.bitsAllocated },
                    { label: 'Bits Stored', value: dialogData.bitsStored },
                    { label: 'High Bit', value: dialogData.highBit },
                    { label: 'Pixel Representation', value: dialogData.pixelRepresentation },
                    { label: 'Window Center', value: dialogData.windowCenter },
                    { label: 'Window Width', value: dialogData.windowWidth },
                    { label: 'Interpretation', value: dialogData.interpretation },
                    { label: 'Scanning Sequence', value: dialogData.scanningSequence }
                  ].map((item, index) => (
                    <Grid item xs={6} key={item.label}>
                      <ListItem sx={{ padding: '1px 8px' }}> {/* Reduced padding for a denser view */}
                        <ListItemIcon>
                          <Icon icon={'twemoji:radio-button'} width="10" />
                          {/* <Description color="primary" fontSize="small" /> Reduced icon size */}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.label}
                          secondary={item.value || '--'} // Display '--' if value is missing or null
                          sx={{
                            wordWrap: 'break-word', // Ensures long text wraps
                            fontSize: '0.875rem', // Slightly smaller text for a denser appearance
                            fontWeight: "bolder"
                          }}
                        />
                      </ListItem>
                    </Grid>
                  ))}
                </Grid>
              </List>
            </DialogContent>
          </Dialog>

          {
            openDiComViewer && (
              <DiComViewerComponent
                open={openDiComViewer}
                onClose={handleCloseDiComViewer}
                imageDetails={seriesImages}
                patientDetails={patientDetails}
              />
            )
          }

        </Card>
        <div style={{
          height: "50px"
        }} />
      </Container >
    </div >
  );
}

export default PatientView;
