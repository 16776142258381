import React, { useEffect, useState } from "react";

//mui-components
import { Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputBase,
  Paper
} from "@mui/material";

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from "@iconify/react/dist/iconify.js";
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

const Mastermenu = ({ steps, activeStep, setActiveStep }) => {
  const [menu, setmenu] = useState([]);
  const [ne, setne] = useState(false);
  const [next, setnext] = useState(false);
  const [open, setopen] = useState(false);
  const [file, setfile] = useState([]);
  const [custommenu, setcustommenu] = useState("");
  const [menucheck, setmenucheck] = useState([]);
  const [userid, setuserid] = useState("");
  const [focus, setfocus] = useState(true);
  const [base, setbase] = useState(true);
  const [doneediting, setdoneediting] = useState(false);
  const [images, setimages] = useState([]);
  const [imagePreview, setimagePreview] = useState(null);
  const [imageData, setimageData] = useState(null);
  const [img, setimg] = useState(null);
  const [selectedmenu, setselectedmenu] = useState([]);

  useEffect(() => {
    console.log("going to get mastermenu list...");
    let user = secureLocalStorage.getItem("userid");
    user = user.replace(/['"]+/g, "");

    setTimeout(() => {
      ApiCalls.getMasterMenuList(user).then((response) => {
        console.log(response.data);
        let list = response.data;

        console.log("going to get custom menu list...");
        ApiCalls.getCustomMenuList(user).then((response) => {
          console.log(response.data);
          setselectedmenu(response.data);
          if (selectedmenu.length > 0) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < response.data.length; j++) {
                if (list[i].menuuuid === response.data[j].menuuid) {
                  list[i].menuText = response.data[j].menuText;
                  list[i].checked = "true";
                }
              }
            }
            setmenu(list);
            for (let k = 0; k < response.data.length; k++) {
              const st = {
                userId: user,
                menuuuid: response.data[k].menuuuid,
                menuText: response.data[k].menuText,
                priority: response.data[k].priority,
                file: file,
              };
              menucheck.push(st);
            }
          } else {
            setmenu(list);
          }
        });
      });
    }, 1000);

    console.log(user);
    setuserid(user);
  }, []);

  const editMenu = () => {
    setfocus(false);
    console.log("look chek");
    setbase(false);
    console.log(base);
    alert(" Tap the text to edit");
    setdoneediting(true);
  }

  const doneEdit = () => {
    setfocus(true);
    console.log("look chek");
    setbase(true);
    setdoneediting(false);
  }

  const handleCheckboxChange = (e, text, priority) => {
    console.log(text);
    console.log(priority);
    console.log(e.target.value);
    console.log(Object.values(file));

    const st = {
      userId: userid,
      menuuuid: e.target.name,
      menuText: text,
      priority: priority,
      // file: file,
    };
    if (e.target.checked) {
      menucheck.push(st);
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to remove " + e.target.value + " from Menu!!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          var temp = [];
          for (var i = 0; i < menucheck.length; i++) {
            let dev = menucheck[i].menuuuid;
            if (dev == e.target.name) {
            } else {
              temp.push(menucheck[i]);
            }
          }
          setmenucheck(temp);
        }
      });
    }
  };

  const handleMenucheck = () => { };

  const forwardSubmit = () => {
    console.log("going to submit...")
    // let menuselect = [];
    // menuselect = menucheck;
    secureLocalStorage.setItem("selectedmenu", JSON.stringify(menucheck));
    let menuMsg;
    let user = secureLocalStorage.getItem("userid");
    user = user.replace(/['"]+/g, '');

    console.log(file);
    console.log(menucheck);

    for (let i = 0; i < file.length; i++) {
      const obj1 = file[i];
      if (obj1.hasOwnProperty('menuText')) {
        const menuicon = obj1.menuicon;
        for (let j = 0; j < menucheck.length; j++) {
          const obj2 = menucheck[j];
          if (obj2.menuText === obj1.menuText) {
            obj2.menuicon = menuicon;
          }
        }
      }
    }

    console.log("if menuicon selected")
    console.log(menucheck)

    const key = 'menuicon';

    for (let i = 0; i < menucheck.length; i++) {
      if (!menucheck[i].hasOwnProperty(key)) {
        menucheck[i][key] = "";
      }
    }
    console.log("final result")
    console.log(menucheck);

    ApiCalls.register("ithings/insertCustomMenu", menucheck)
      .then((response) => {
        console.log("response of apicall")
        console.log(response.data);
        if (response.data[0].status.message === "Success") {
          setnext(!next);
          setActiveStep(2);
        }
      })
  }

  const fileInput = (e, menutext) => {
    console.log(e.target.files[0]);
    console.log(menutext);
    if (e.target.files.length == 0) {
      console.log("file removed");
      console.log(e.target.files);
      console.log(menutext);
      var array = [...file]; // make a separate copy of the array
      const index3 = array.findIndex(item => item.menuText === menutext)
      console.log('Index3: ', index3); // Found the object index
      // var index = array.indexOf(menutext)
      // console.log(index)
      if (index3 !== -1) {
        array.splice(index3, 1);
        console.log(JSON.stringify(array));
        setfile(array);
      }
      console.log("Icons after file removed : " + JSON.stringify(array));
    } else {
      console.log("file added")
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      const allowedFileTypes = ["png"];
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        return false;
      } else if (e.target.files[0].size > 300000) {
        window.alert("Please upload a file smaller than 300kb");
        return false;
      } else {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        ApiCalls.storeFile("uploadCustomMenuandIcon", formData)
          .then((response) => {
            console.log(response.data);
            if (response.data.message === "File Uploaded Successfully") {
              let filename = e.target.files[0].name
              setfile([
                ...file,
                {
                  menutext: menutext,
                  menuicon: filename,
                }
              ]);
              console.log("Icons selected: " + JSON.stringify(file));
            }
          })
          .catch((error) => {
            console.log(error);
          })

      }

    }
  }

  return (
    <div style={{ padding: '20px', width: 600 }}>
      {base ? (
        <Button
          sx={{
            fontFamily: "Lato, sans-serif",
            backgroundColor: "#1976D2",
            padding: "6px 12px", // Reduced padding for consistent height
            alignItems: "center",
            marginBottom: 0,
            height: "38px", // Adjust height to match the checkbox
            lineHeight: "18px", // Ensure text is vertically centered
            borderRadius: "5px",
            boxShadow: "none",
          }}
          startIcon={<Icon icon={'iconamoon:edit-fill'} />}
          onClick={editMenu}
          variant="contained"
        >
          Change Menu Name
        </Button>
      ) : (
        <Button
          sx={{
            fontFamily: "Lato, sans-serif",
            backgroundColor: "#1976D2",
            padding: "6px 12px", // Reduced padding for consistent height
            alignItems: "center",
            marginBottom: 0,
            height: "38px", // Adjust height to match the checkbox
            lineHeight: "18px", // Ensure text is vertically centered
            borderRadius: "5px",
            boxShadow: "none",
          }}
          startIcon={<Icon icon={'lets-icons:done-round'} />}
          onClick={doneEdit}
          variant="contained"
        >
          Done Editing
        </Button>
      )}

      <Grid container spacing={2} justifyContent="center">
        {menu.map((menutext, index) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
            <Paper
              sx={{
                backgroundColor: "#F4F6F8",
                boxShadow: 5,
                mt: 2,
                height: "auto",
                width: "100%", // Full width
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                py: 2,
                px: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: "column",
                  justifyContent: 'space-between',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={menutext.menuText}
                      name={menutext.menuuuid}
                      onChange={(e) =>
                        handleCheckboxChange(e, menutext.menuText, menutext.priority)
                      }
                      defaultChecked={menutext.checked}
                    />
                  }
                  label={
                    <InputBase
                      readOnly={focus}
                      name={menutext.menuuuid}
                      sx={
                        base
                          ? { color: "#090A0A", width: "200px" }
                          : {
                            color: "#1976D2",
                            borderColor: "blue",
                            border: "2px solid #38A3A5",
                            borderRadius: "5px",
                            width: "200px",
                            height: "50px",
                            px: 2,
                          }
                      }
                      autoFocus={base}
                      defaultValue={menutext.menuText}
                      onChange={(e) => {
                        menutext.menuText = e.target.value;
                        handleMenucheck(e, menutext.menuText);
                      }}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 1 }}>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(e) => fileInput(e, menutext.menuText)}
                    style={{ display: 'none' }} // Hide the input element
                    id={`upload-button-${index}`} // Unique ID for each upload button
                  />
                  <label htmlFor={`upload-button-${index}`}>
                    <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                      <Button
                        variant="outlined"
                        component="span"
                        size="small"
                        startIcon={<Upload />}
                      >
                        Upload
                      </Button>
                      <span style={{ marginLeft: "10px", fontSize: "13px" }}>
                        (*optional)
                      </span>
                    </Box>
                  </label>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Container
        sx={{
          display: 'flex',
          marginTop: "20px",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          sx={{ marginLeft: '30%' }}
          variant="contained"
          color="primary"
          onClick={() => (setActiveStep(0))}
          startIcon={<Icon icon={'mdi:navigate-before'} />}
        >
          Prev
        </Button>
        <Button
          disabled={doneediting}
          sx={{ marginLeft: '20px' }}
          variant="contained"
          color="primary"
          endIcon={<Icon icon={'mdi:navigate-next'} />}
          onClick={() => {
            // setActiveStep(2);
            forwardSubmit();
          }}
        >
          Next
        </Button>
      </Container>

    </div>
  );
};

export default Mastermenu;
