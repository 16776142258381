import React, { useEffect, useState } from 'react';
import ChartVitalDisplay from './ChartVitalDisplay';
import BottomContainer from './BottomContainer';
import DialogBox from './DialogBox';
import BplLiveChart from './BplLiveChart';
import GraphPump from './GraphPump';

const GraphBplNew = () => {
    const [deviceID, setDeviceID] = useState('');
    const [patientThreshold, setPatientThreshold] = useState({});
    const [hrValue, setHrValue] = useState(null);
    const [rrValue, setRrValue] = useState(null);
    const [spo2Value, setSpo2Value] = useState(null);
    const [nibpSystolic, setNibpSystolic] = useState(null);
    const [nibpDiastolic, setNibpDiastolic] = useState(null);
    const [nibpMean, setNibpMean] = useState(null);
    const [t1Value, setT1Value] = useState(null);
    const [t2Value, setT2Value] = useState(null);
    const [prValue, setPrValue] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [slide, setSlide] = useState([
        { min: 50, max: 120 },
        { min: 90, max: 100 },
        { min: 8, max: 30 }
    ]);
    console.log("The slide", slide.min, slide.max);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const deviceId = "22824";
        if (deviceId) {
            setDeviceID(deviceId);
        } else {
            console.warn('deviceId is undefined');
        }
    }, []);


    useEffect(() => {
        if (deviceID === '100924') {
            <GraphPump deviceID={deviceID} />
        }
        //const ws = new WebSocket("ws://localhost:8081/consumer");
        const ws = new WebSocket("wss://itouch.iorbit.health:8081/consumer");
        ws.onopen = () => {
            console.log('WebSocket connection opened', deviceID);
            ws.send(JSON.stringify({
                "PacketID": "WS_DEVICE_CONSUME_REQ",
                "DeviceUUID": deviceID,
                "SamplingRate": 250,
                "WindowSize": 10,
                "MinYVal": 0,
                "MaxYVal": 128
            }));
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        let buffer = [];
        const THROTTLE_INTERVAL = 10000; // 1 minute

        const processBuffer = () => {
            if (buffer.length > 0) {
                const message = buffer.shift();
                if (message && message.valueQuantity && message.valueQuantity.value) {
                    switch (message.code.text) {
                        case "Heart rate":
                            setHrValue(message.valueQuantity.value);
                            break;
                        case "Respiratory rate":
                            setRrValue(message.valueQuantity.value);
                            break;
                        case "Oxygen saturation in Arterial blood":
                            setSpo2Value(message.valueQuantity.value);
                            break;
                        case "Pulse rate":
                            setPrValue(message.valueQuantity.value);
                            break;
                        case "Systolic blood pressure":
                            setNibpSystolic(message.valueQuantity.value);
                            break;
                        case "Diastolic blood pressure":
                            setNibpDiastolic(message.valueQuantity.value);
                            break;
                        case "Mean blood pressure":
                            setNibpMean(message.valueQuantity.value);
                            break;
                        case "Body temperature T1":
                            setT1Value(message.valueQuantity.value);
                            break;
                        case "Body temperature T2":
                            setT2Value(message.valueQuantity.value);
                            break;
                        default:
                            console.log("Unknown observation type:", message.code.text);
                    }
                }
            }
        };
        ws.onmessage = (e) => {
            const message = JSON.parse(e.data);
            buffer.push(message);
        };

        const intervalId = setInterval(processBuffer, THROTTLE_INTERVAL);


        return () => {
            clearInterval(intervalId);
            ws.close();
        };
    }, [deviceID]);

    useEffect(() => {
        const handleMessage = (event) => {
            console.log('Message received:', event);
            console.log('Message origin:', event.origin);
            console.log('Message data:', event.data);

            // Ensure the message is coming from the expected origin
            if (event.type === "message" &&
                (event.origin === "" ||
                    event.origin === 'http://10.0.2.2:3000' ||
                    event.origin === 'http://localhost:3000' ||
                    event.origin === 'https://dev.iorbit.health' ||
                    event.origin === 'http://192.168.1.175:3000')) {
                let data;
                if (typeof event.data === 'string') {
                    if (event.data) {
                        try {
                            data = JSON.parse(event.data);
                        } catch (e) {
                            console.error('Invalid JSON:', e);
                            return;
                        }
                    } else {
                        console.warn('Empty string received');
                        return;
                    }
                } else {
                    data = event.data;
                }

                if (data) {
                    try {
                        const extractedData = JSON.parse(JSON.stringify(data));
                        const deviceId = extractedData?.deviceid;
                        const stringPatient = extractedData?.stringPatient ? decodeURIComponent(extractedData.stringPatient) : '';

                        // Important alerts for testing
                        // alert("deviceId: " + deviceId);
                        // alert("stringPatient: " + stringPatient);

                        if (deviceId) {
                            setDeviceID(deviceId);
                        } else {
                            console.warn('deviceId is undefined');
                        }

                        if (stringPatient) {
                            setPatientThreshold(JSON.parse(stringPatient));
                        } else {
                            console.warn('stringPatient is undefined or empty');
                        }
                    } catch (e) {
                        console.error('Error processing data:', e);
                    }
                } else {
                    console.warn('Data is undefined');
                }
            } else {
                console.warn('Unexpected message origin:', event.origin);
            }
        };

        window.addEventListener('message', handleMessage, true);

        return () => {
            window.removeEventListener('message', handleMessage, true);
        };
    }, []);


    // const slideInfo = [
    //     {
    //         color: '#7CFC00',
    //         range: { min: 120, max: 50 },
    //         tag: 'HR',
    //         value: 'Ecg',
    //         unit: 'bpm'
    //     },
    //     {
    //         color: '#009FE3',
    //         range: { min: 100, max: 90 },
    //         tag: 'SpO₂',
    //         value: 'SPO2',
    //         unit: '%'
    //     },
    //     {
    //         color: '#F2B809',
    //         range: { min: 30, max: 8 },
    //         tag: 'RR',
    //         value: 'RR',
    //         unit: 'rpm'
    //     }
    // ];
    useEffect(() => {
        if (patientThreshold) {
            setSlide([
                {
                    min: parseFloat(patientThreshold.hrmin) || 50,
                    max: parseFloat(patientThreshold.hrmax) || 120
                },
                {
                    min: parseFloat(patientThreshold.spo2min) || 90,
                    max: parseFloat(patientThreshold.spo2max) || 100
                },
                {
                    min: parseFloat(patientThreshold.rrmin) || 8,
                    max: parseFloat(patientThreshold.rrmax) || 30
                }
            ]);
        }
    }, [patientThreshold]);

    const slideInfo = [
        {
            color: '#7CFC00',
            range: { min: slide[0].min, max: slide[0].max },
            tag: 'HR',
            value: 'Ecg',
            unit: 'bpm'
        },
        {
            color: '#009FE3',
            range: { min: slide[1].min, max: slide[1].max },
            tag: 'SpO₂',
            value: 'SPO2',
            unit: '%'
        },
        {
            color: '#F2B809',
            range: { min: slide[2].min, max: slide[2].max },
            tag: 'RR',
            value: 'RR',
            unit: 'rpm'
        }
    ];
    const openDialogfunction = (index) => {
        setOpenDialog(true);
        setIndex(index);
    };

    return (
        <div style={{ width: "100%", height: '100vh', backgroundColor: '#080f18' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '100%', height: '70vh' }}>
                    <BplLiveChart parameterName='ECG' hrValue={hrValue} />
                </div>
            </div>
        </div >
    );
};

export default GraphBplNew;
