import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

//mui-components
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

//components
import Iconify from '../../components/iconify';

//externals
import { Icon } from '@iconify/react';
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript'; // Language support for JavaScript (includes JSON)
import 'prismjs/themes/prism-tomorrow.css'; // You can choose a Prism theme you like
import Editor from 'react-simple-code-editor';
//-----------------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const highlightWithPrism = (code) =>
    Prism.highlight(code, Prism.languages.javascript, 'javascript');

//-----------------------------------------------------------------------------

const DeviceProfile = ({ data, formData, setFormData, handleChange, handleStreamEnabled, handleVitalsEnabled, onChangeX, handleNext }) => {

    const [profileImage, setProfileImage] = useState('');
    const [modelName, setModelName] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [version, setVersion] = useState('');
    const [tags, setTags] = useState('');
    const [manufacturer, setManufacturer] = useState('');

    const [modelNameError, setModelNameError] = useState(false);
    const [modelNumberError, setModelNumberError] = useState(false);
    const [hover, setHover] = useState(false);
    const [redirectToList, setRedrectToList] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false); // State to handle dialog open/close


    const handleBlur = (event) => {
        const name = event.target.name;
        if (name === 'modelName') {
            setModelNameError(!event.target.value);
        } else if (name === 'modelNumber') {
            setModelNumberError(!event.target.value);
        }
    }

    const handleFocus = (event) => {
        const name = event.target.name;
        if (name === 'modelName') {
            setModelNameError(false);
        } else if (name === 'modelNumber') {
            setModelNumberError(false);
        }
    }

    // Handle opening and closing of prompt dialog
    const handleOpenPromptDialog = () => setOpenPromptDialog(true);
    const handleClosePromptDialog = () => {
        setOpenPromptDialog(false); // Close the dialog
    };

    // Handle prompt submission
    const handlePromptSubmit = () => {
        // Just close the dialog after submission without clearing promptText
        setOpenPromptDialog(false);
    };

    const handleAddProfileDetails = () => {
        console.log(data);
        if (!data.modelName) {
            setModelNameError(true);
        } else if (!data.modelNumber) {
            setModelNumberError(true);
        } else {
            setModelNameError(false);
            setModelNumberError(false);
            handleNext();
        }
    }

    const handleCancelDeviceProfile = () => {
        setRedrectToList(true);
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    if (redirectToList) {
        return (
            <Redirect to={'/deviceprofile'} />
        )
    }

    return (
        <Container sx={{ marginTop: 5, minWidth: 800 }}>
            <Box sx={{ width: 1 }}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                    <Box gridColumn="span 4">
                        <Item>
                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 10 }}>
                                <Avatar
                                    alt="Image"
                                    variant="rounded"
                                    src={data.devImage}
                                    sx={{
                                        marginLeft: 5,
                                        width: 150,
                                        height: 150,
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            transform: 'scale(1.1)',
                                        },
                                    }}
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                    onClick={() => document.getElementById('avatar-input').click()}
                                >
                                    <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                        {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="tabler:device-ipad-horizontal-cog" color="#4481eb" width="80" />}
                                        <div className="text-container">
                                            <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                        </div>
                                    </div>
                                </Avatar>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="avatar-input"
                                    onChange={onChangeX}
                                    style={{ display: 'none', }}
                                />
                            </Stack>
                        </Item>
                    </Box>
                    <Box gridColumn="span 8">
                        <Item>
                            <Stack direction="column" spacing={2} justifyContent="space-between">
                                <TextField
                                    name="modelName"
                                    label={
                                        <span>
                                            Model Name{' '}
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    value={data.modelName}
                                    fullWidth
                                    error={modelNameError}
                                    helperText={modelNameError && "Profile Name is required"}
                                />
                                <TextField
                                    name="modelNumber"
                                    label={
                                        <span>
                                            Model Number{' '}
                                            <span style={{ color: 'red' }}>*</span>
                                        </span>
                                    }
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    fullWidth
                                    value={data.modelNumber}
                                    onChange={handleChange}
                                    error={modelNumberError}
                                    helperText={modelNumberError && "Model Number is required"}
                                />
                                <TextField
                                    name="versionNumber"
                                    label="Version"
                                    fullWidth
                                    value={data.versionNumber}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="modelTags"
                                    label="Tags"
                                    placeholder="#modelName"
                                    value={data.modelTags}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <TextField
                                    name="modelmanufacturer"
                                    label="Manufacturer"
                                    fullWidth
                                    value={data.modelmanufacturer}
                                    onChange={handleChange}
                                />

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-around"
                                >
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={data.streamEnabled}
                                            name="streamEnabled"
                                            onChange={handleStreamEnabled}
                                        />}
                                        label="Enable Streaming"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={data.vitalsEnabled}
                                            name="vitalsEnabled"
                                            onChange={handleVitalsEnabled}
                                        />}
                                        label="Enable Vitals"
                                    />
                                </Stack>


                                <Stack direction="row" spacing={2} justifyContent="space-around" >
                                    <Button
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        color="error"
                                        variant="outlined"
                                        onClick={handleCancelDeviceProfile}
                                        onMouseDown={handleMouseDown}
                                        sx={{
                                            borderRadius: "12px",
                                        }}
                                        startIcon={
                                            <Iconify icon={'material-symbols:cancel-presentation'} />
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    {/* Add Prompt Button */}
                                    <Button
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        sx={{
                                            borderRadius: "12px",
                                        }}
                                        onClick={handleOpenPromptDialog}
                                        onMouseDown={handleMouseDown}
                                        startIcon={<Iconify icon={'humbleicons:prompt'} />}
                                    >
                                        Add/Edit Prompt
                                    </Button>
                                    <Button
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleAddProfileDetails}
                                        onMouseDown={handleMouseDown}
                                        sx={{
                                            borderRadius: "12px",
                                        }}
                                        endIcon={
                                            <Iconify icon={'ic:twotone-arrow-right'} />
                                        }
                                    >
                                        Next
                                    </Button>
                                </Stack>
                            </Stack>
                        </Item>
                    </Box>
                </Box>
            </Box>

            {/* Prompt Dialog */}
            <Dialog open={openPromptDialog} onClose={handleClosePromptDialog} fullWidth maxWidth="md">
                <DialogTitle>Add a Prompt

                    {/* Close Icon Button */}
                    <IconButton
                        aria-label="close"
                        onClick={handleClosePromptDialog}
                        onMouseDown={handleMouseDown}
                        color="primary"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* Simple code editor with syntax highlighting */}
                    <Editor
                        value={data.promptText}
                        onValueChange={(code) => {
                            const syntheticEvent = {
                                target: {
                                    name: "promptText",
                                    value: code
                                }
                            };
                            handleChange(syntheticEvent); // Update the formData with the new promptText
                        }}
                        highlight={highlightWithPrism}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 15,
                            minHeight: '200px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            color: '#333', // Darker font color
                            backgroundColor: '#f9f9f9', // Slightly off-white background,
                            fontWeight: 545
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handlePromptSubmit}
                        onMouseDown={handleMouseDown}
                        color="primary"
                        variant="outlined"
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default DeviceProfile
