import React, { useEffect, useState } from "react";

//mui-components
import { Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  Paper,
  Tooltip
} from "@mui/material";

//services
import secureLocalStorage from "react-secure-storage";
import ApiCalls from "../../services/try";

//externals
import { Icon } from "@iconify/react/dist/iconify.js";

const OrganisationStructure = ({ steps, activeStep, setActiveStep, isMedicalDomainsChecked, setIsMedicalDomainsChecked }) => {
  const [roles, setroles] = useState("customer");
  const [IsInEditMode, setIsInEditMode] = useState(false);
  const [role, setrole] = useState([]);
  const [val, setval] = useState(false);
  const [ne, setne] = useState(false);
  const [next, setnext] = useState(false);
  const [roleselected, setroleselected] = useState([]);
  const [rolescheck, setrolescheck] = useState([]);
  const [userid, setuserid] = useState("");
  const [id, setid] = useState("2");
  const [open, setopen] = useState(false);
  const [focus, setfocus] = useState(true);
  const [base, setbase] = useState(true);
  const [rolelist, setrolelist] = useState([]);
  const [doneediting, setdoneediting] = useState(false);

  useEffect(() => {
    var user = secureLocalStorage.getItem("userid");
    user = user.replace(/['"]+/g, "");
    setuserid(user);

    ApiCalls.getRoleList().then((response) => {
      let list = response.data;
      ApiCalls.getSelectedRoleList(user).then((result) => {
        setrolelist(result.data);
        if (result.data.length > 0) {
          for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < result.data.length; j++) {
              if (list[i].roleId === result.data[j].roleId) {
                list[i].roleName = result.data[j].roleName;
                list[i].checked = true;
              }
            }
          }
          setrole(list);
          const updatedRolesCheck = result.data.map((roleData) => ({
            userId: user,
            roleId: roleData.roleId,
            customRoleName: roleData.roleName,
          }));
          setrolescheck(updatedRolesCheck);
        } else {
          const initialRolesCheck = list.map((roleItem) => ({
            userId: user,
            roleId: roleItem.roleId,
            customRoleName: roleItem.roleName,
          }));
          setrolescheck(initialRolesCheck);
          setrole(list);
        }
      });
    });
  }, []);

  const editAll = () => {
    setfocus(false);
    setbase(false);
    alert("Tap the Text to Edit");
    setdoneediting(true);
  };

  const doneEditing = () => {
    setfocus(true);
    setbase(true);
    setdoneediting(false);
  };

  const Rolecheck = (e, s) => {
    e.preventDefault();
    setrolescheck((prevRolesCheck) =>
      prevRolesCheck.map((role) =>
        role.roleId === e.target.name ? { ...role, customRoleName: s } : role
      )
    );
  };

  const handleCheckboxChange = (e, index, s) => {
    setrolescheck((prevRolesCheck) => {
      const updatedRolesCheck = [...prevRolesCheck];
      const roleIndex = updatedRolesCheck.findIndex(
        (role) => role.roleId === e.target.name
      );

      if (e.target.checked) {
        updatedRolesCheck[roleIndex] = {
          ...updatedRolesCheck[roleIndex],
          customRoleName: s,
        };
      } else {
        updatedRolesCheck.splice(roleIndex, 1);
      }

      return updatedRolesCheck;
    });
  };

  const forwardSubmit = () => {
    ApiCalls.insertSelectedRoles(rolescheck).then((response) => {
      if (response.data.successMessage === "RoleName changed.") {
        secureLocalStorage.setItem("selectedroles", JSON.stringify(rolescheck));
        setnext(!next);
      }
    });
  };

  const a = [
    "Tenant is the first level of the hierarchical structure in iTouch",
    "Company is the next level of the hierarchical structure which comes after Tenant in iTouch",
    "Physician is the next level of the hierarchical structure which comes after Tenant and Company in iTouch",
    "Patient is the next level of the hierarchical structure which comes after Tenant, Company and Physician in iTouch",
    "Various Actors, except Patient-level users, can have the privileges to create new devices",
  ];

  let roleselect = role.map((rolename, index) => (
    <Paper
      key={index}
      sx={{
        backgroundColor: "#F4F6F8",
        boxShadow: 5,
        my: 1,
        height: 100,
        maxWidth: 500,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        px: 3,
        py: 3,
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            value={rolename.roleName}
            name={rolename.roleId}
            checked={rolescheck.some((role) => role.roleId === rolename.roleId)} // check if the role is in rolescheck
            onChange={(e) => handleCheckboxChange(e, index, rolename.roleName)}
          />
        }
        label={
          <InputBase
            readOnly={focus}
            name={rolename.roleId}
            sx={
              base
                ? { color: "#090A0A" }
                : {
                  color: "#1976D2",
                  bordercolor: "blue",
                  border: "2px solid #38A3A5",
                  borderRadius: "5px",
                  width: "200px",
                  height: "50px",
                  px: 2,
                }
            }
            autoFocus={base}
            defaultValue={rolename.roleName}
            onChange={(e) => {
              rolename.roleName = e.target.value;
              Rolecheck(e, rolename.roleName);
            }}
          />
        }
      />
      <Tooltip arrow placement="right" color="primary" disableFocusListener interactive title={a[index]}>
        <Info />
      </Tooltip>
    </Paper>
  ));

  return (
    <div bgcolor="#E8F6EF">
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>

        {base ? (
          <Button
            size="small"
            sx={{
              fontFamily: "Lato, sans-serif",
              backgroundColor: "#1976D2",
              padding: "6px 12px", // Reduced padding for consistent height
              alignItems: "center",
              marginBottom: 0,
              height: "38px", // Adjust height to match the checkbox
              lineHeight: "18px", // Ensure text is vertically centered
              borderRadius: "5px",
              boxShadow: "none",
            }}
            startIcon={<Icon icon={'iconamoon:edit-fill'} />}
            onClick={editAll}
            variant="contained"
          >
            Change Role Names
          </Button>
        ) : (
          <Button
            size="small"
            sx={{
              fontFamily: "Lato, sans-serif",
              backgroundColor: "#1976D2",
              padding: "6px 12px", // Reduced padding for consistent height
              alignItems: "center",
              marginBottom: 0,
              height: "38px", // Adjust height to match the checkbox
              lineHeight: "18px", // Ensure text is vertically centered
              borderRadius: "5px",
              boxShadow: "none",
            }}
            startIcon={<Icon icon={'lets-icons:done-round'} />}
            onClick={doneEditing}
            variant="contained"
          >
            Done Editing
          </Button>
        )}

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={isMedicalDomainsChecked}
              onChange={() => {
                setIsMedicalDomainsChecked(!isMedicalDomainsChecked);
              }}
              sx={{
                color: "#ffffff",
                "&.Mui-checked": {
                  color: "#ffffff",
                },
              }}
            />
          }
          label={
            <span
              style={{
                fontFamily: "Lato, sans-serif",
                color: "#ffffff",
                fontSize: "14px",
                lineHeight: "16px", // Adjust line height for vertical centering
              }}
            >
              Medical Domains
            </span>
          }
          style={{
            backgroundColor: "#1976D2", // Primary blue background
            width: "180px",
            height: "35px", // Adjust height to match the button
            borderRadius: "5px",
            display: 'flex',
            alignItems: 'center', // Align text and checkbox vertically
            padding: '0 12px', // Add padding for consistent spacing
            gap: "4px", // Add small gap between checkbox and text
            marginTop: "5px"
          }}
        />
      </div>

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        {roleselect}
      </Box>

      <Button
        disabled={doneediting}
        variant="contained"
        color="primary"
        sx={{
          float: "right",
          marginBottom: 5,
          marginTop: 1,
        }}
        endIcon={<Icon icon={'mdi:navigate-next'} />}
        onClick={() => {
          setActiveStep(1);
          forwardSubmit();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export default OrganisationStructure;
