import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

//mui components
import {
    Button,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Container,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Grid,
    Paper
} from '@mui/material';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from "@iconify/react/dist/iconify.js";
import secureLocalStorage from 'react-secure-storage';

const PatientPrivilege = ({ steps, activeStep, setActiveStep, isMedicalDomainsChecked }) => {

    const [roles, setroles] = useState([]);
    const [privileges, setPrivileges] = useState([
        {
            userId: "", // Add user-specific ID
            roleId: "", // Add role-specific ID
            id: "patientInfo",
            label: "Patient Info",
            value: true // default set to true
        },
        {
            userId: "",
            roleId: "",
            id: "patientTrend",
            label: "Patient Trend",
            value: true // default set to true
        },
        {
            userId: "",
            roleId: "",
            id: "patientDevice",
            label: "Patient Device",
            value: true // default set to true
        },
        {
            userId: "",
            roleId: "",
            id: "patientAdmission",
            label: "Patient Admission",
            value: true // default set to true
        },
        {
            userId: "",
            roleId: "",
            id: "patientWaveform",
            label: "Patient Waveform",
            value: true // default set to true
        }
    ]);

    const [userid, setuserid] = useState('');
    const [rolename, setrolename] = useState('');
    const [roleId, setroleId] = useState('');

    const [open, setopen] = useState(false);
    const [hide, sethide] = useState(true);
    const [loading, setloading] = useState(false);
    const [redirectToDashboard, setRedirectToDashboard] = useState(false)

    const [accumulatedPermissions, setAccumulatedPermissions] = useState({});


    useEffect(() => {
        let user = secureLocalStorage.getItem('userid');
        console.log(user)
        user = user.replace(/['"]+/g, '');
        console.log(user)
        setuserid(user);
        let arrayval = [];


        if (secureLocalStorage.getItem('selectedroles')) {
            let abc = JSON.parse(secureLocalStorage.getItem('selectedroles'));
            for (var i = 0; i < abc.length; i++) {
                if (abc[i].roleId == "4" | abc[i].roleId == "6") {
                    //this.setState({roles:abc[i]});
                    abc[i].selected = "no";
                    arrayval.push(abc[i]);

                }

            }
            setroles(arrayval);
            sethide(false);
        }
    }, [])

    const handleOpen = (e, roleName, roleId) => {
        console.log("***", e.target, "***", roleName, "***", roleId, "***");
        setrolename(roleName)
        setroleId(roleId);
        setopen(true);
    }

    const handleClose = () => {
        setopen(false)
    }

    if (redirectToDashboard) {
        return (<Redirect to={'/dashboard'} />);
    }

    let showrole = roles.map(function (newrolename, index) {

        return (
            <CssBaseline>
                <Paper style={{ marginTop: "5px", backgroundColor: "#f5f5f5", width: "350px", height: "100px" }}>
                    <div
                        style={{
                            padding: "20px",
                            textalign: "left",
                            fontSize: "20px",
                            color: '#607D8B',
                            display: 'flex',
                            justifyContent: "space-between",
                            textAlign: 'center',
                            marginTop: "20px",
                        }}
                    >
                        <p style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginTop: "20px",
                        }}>
                            {newrolename.customRoleName}
                        </p>
                        <Button
                            color='primary'
                            size="medium"
                            value={newrolename.customRoleName}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => handleOpen(e, newrolename.customRoleName, newrolename.roleId)}
                        >
                            Add Previleges
                        </Button>
                    </div>
                </Paper>
            </CssBaseline>
        )
    })

    const handleChangePermissions = (e) => {
        const menuId = e.target.name;

        setAccumulatedPermissions((prevPermissions) => {
            return {
                ...prevPermissions,
                [menuId]: e.target.checked,
            };
        });
    }

    let showmenu = privileges.map(function (privilege, index) {
        return (
            <div key={index} style={{
                padding: '15px',
                textAlign: 'left',
                fontSize: '20px',
            }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            value={privilege.id}
                            onChange={handleChangePermissions}
                            defaultChecked={privilege.value}  // Set defaultChecked based on privilege.value
                            name={privilege.id}
                        />
                    }
                    label={privilege.label}
                />
            </div>
        );
    });

    const finishSetup = () => {
        setRedirectToDashboard(true);
    }

    const disable = () => {
        // Get a list of all privilege IDs
        const allPrivilegeIds = privileges.map(privilege => privilege.id);

        // Create a new object with all privileges, defaulting to false if not present
        const updatedPermissions = allPrivilegeIds.reduce((acc, privilegeId) => {
            acc[privilegeId] = accumulatedPermissions[privilegeId] || false;
            return acc;
        }, {});

        console.log(updatedPermissions);

        ApiCalls.register("userId/" + userid + "/roleId/" + roleId + "/accessingpatientdata", updatedPermissions)
            .then((response) => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    handleClose();
                }
            })
    }


    return (
        <div>
            <CssBaseline />
            <Container>
                < Grid container spacing={3} >
                    <Grid item xs={6}>
                        {showrole}
                    </Grid>
                </Grid>
                <br /><br />
                <Container style={{ display: 'flex', marginTop: "20px", justifyContent: "space-around" }}>
                    <Button
                        style={{ marginLeft: '30%' }}
                        variant="contained"
                        color="primary"
                        startIcon={<Icon icon={'mdi:navigate-before'} />}
                        onClick={() => (setActiveStep(2))}
                    >
                        Prev
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Icon icon={'material-symbols:done'} />}
                        onClick={() => {
                            finishSetup();
                        }}
                    >
                        Finish
                    </Button>
                </Container>
            </Container>


            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    <DialogContentText className='font-weight-bold text-muted'>
                        Grant Permissions for {rolename}
                    </DialogContentText>
                    <CardHeader title="Select Permissions" />
                    <CardContent>
                        {showmenu}
                    </CardContent>
                </DialogContent>
                <DialogActions>
                    {
                        loading ? <CircularProgress size={50} /> :
                            <div>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    size='small'
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    size='small'
                                    style={{
                                        marginLeft: '20px'
                                    }}
                                    onClick={disable}
                                >
                                    Submit
                                </Button>
                            </div>
                    }
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default PatientPrivilege;
