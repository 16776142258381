import React, { useEffect, useRef, useState } from "react";

// externals
import { Icon } from "@iconify/react/dist/iconify.js";
import secureLocalStorage from "react-secure-storage";
import ReactToPrint from "react-to-print";

// internals
import Footer from "./components/Footer";
import Header from "./components/Header";
import ReportContent from "./components/ReportContent";
import "./styles/PatientReport.css";

// mui-components
import { Dialog } from "@mui/material";

// services
import ApiCalls from "../../services/try";

const DicomReport = ({
  open,
  onClose,
  impression,
  findings,
  recommendations,
  notes,
  signatureUrl,
  dicomImage,
  patientDetails,
  formattedReport,
}) => {
  const [patientData, setPatientData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const reportRef = useRef(); // Create a ref for the printable content

  useEffect(() => {
    setLoading(true);
    const companyId = secureLocalStorage.getItem("compId");
    const physicianId = secureLocalStorage.getItem("physicianId");
    const patientId = patientDetails.patientId;

    console.log(patientId + "***");
    console.log(formattedReport + "----");

    ApiCalls.getdetails(
      `report/getdicomreportid?reportno=${formattedReport}`
    ).then((response) => {
      if (response.status === 200 && response.data.reportid !== undefined) {
        const reportId = response.data.reportid;
        ApiCalls.getdetails(
          `companyid/${companyId}/physisicanid/${physicianId}/patientid/${patientId}/reportid/${reportId}/getdicomreport`
        )
          .then((response1) => {
            if (response1.status === 200 && response1.data) {
              const data = response1.data;
              setPatientData(data);
            } else {
              setPatientData([]);
              // throw new Error("Failed to fetch report details");
            }
          })
          .catch((error) => {
            console.error("Error fetching report details:", error);
            setError("Failed to load existing report details.");
          })
          .finally(() => setLoading(false));
      } else {
        // throw new Error("Failed to fetch report ID");
      }
    });
  }, []);

  useEffect(() => {
    console.log("DicomReport...");
  }, []);

  const currentPage = 1;
  const totalPages = 1;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <div>
        {loading ? (
          <div className="loading-container">
            <Icon icon="eos-icons:bubble-loading" width="100" />
          </div>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="patient-report" ref={reportRef}>
            <Header patientData={patientData} />
            <ReportContent
              patientData={patientData}
              signatureUrl={signatureUrl}
              dicomImage={dicomImage}
            />
            <Footer pageNumber={currentPage} totalPages={totalPages} />
          </div>
        )}

        <div className="print-button-container">
          <button
            className="cancel-button"
            onClick={onClose} // Call the onClose function to close the dialog
            onMouseDown={(event) => event.preventDefault()} // Prevent focus box
          >
            <Icon icon="mdi:close" className="cancel-icon" /> Cancel
          </button>

          <ReactToPrint
            trigger={() => (
              <button
                className="print-button"
                onMouseDown={(event) => event.preventDefault()} // Prevents focus on mouse click
              >
                <Icon icon={"mdi:printer"} className="print-icon" />{" "}
                Print/Download Report
              </button>
            )}
            content={() => reportRef.current} // Ensure this matches the ref assignment
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DicomReport;
