import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

//mui components
import {
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Paper
} from '@mui/material';

//services
import secureLocalStorage from 'react-secure-storage';
import ApiCalls from "../../services/try";

//externals
import { Icon } from "@iconify/react/dist/iconify.js";

const Rolemenu = ({ steps, activeStep, setActiveStep, isMedicalDomainsChecked }) => {
  const [roles, setroles] = useState([]);
  const [mastermenu, setmastermenu] = useState([]);
  const [next, setnext] = useState(false);
  const [selectedmenu, setselectedmenu] = useState([]);
  const [rolename, setrolename] = useState('');
  const [userid, setuserid] = useState('');
  const [hide, sethide] = useState(true);
  const [appname, setappname] = useState('');
  const [roleselect, setroleselect] = useState('');
  const [menuList, setmenuList] = useState([]);
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [disableRole, setdisableRole] = useState([]);
  const [showNextButton, setShowNextButton] = useState(true);
  useEffect(() => {
    let user = secureLocalStorage.getItem('userid');
    console.log(user)
    user = user.replace(/['"]+/g, '');
    console.log(user)
    setuserid(user);
    let arrayval = [];


    if (secureLocalStorage.getItem('selectedroles')) {
      let abc = JSON.parse(secureLocalStorage.getItem('selectedroles'));
      for (var i = 0; i < abc.length; i++) {
        if (abc[i].roleId == "3" | abc[i].roleId == "4" | abc[i].roleId == "6" | abc[i].roleId == "8") {
          //this.setState({roles:abc[i]});
          abc[i].selected = "no";
          arrayval.push(abc[i]);

        }

      }
      setroles(arrayval);
      sethide(false);
    }
  }, [])

  const handleOpen = (e, s, id) => {
    setmastermenu([]);
    setroleselect(id);
    console.log("rolename: " + s);
    setrolename(s);
    setselectedmenu([]);
    setmenuList([]);
    let user = secureLocalStorage.getItem('userid');
    user = user.replace(/['"]+/g, '');
    let a = []
    let list = []
    ApiCalls.getCustomMenuList(user).then((result) => {
      console.log(result.data);
      if (id == 3) {
        list = result.data.filter(role => {
          return role.priority != 3 && role.priority != 4 && role.priority != 5 && role.priority != 6 && role.priority != 8 && role.priority != 9 && role.priority != 10 && role.priority != 11 && role.priority != 12
        })
      }
      if (id == 4) {
        list = result.data.filter(role => {
          return role.priority != 2 && role.priority != 7 && role.priority != 9 && role.priority != 10 && role.priority != 11
        })
      }
      if (id == 6) {
        list = result.data.filter(role => {
          return role.priority != 2 && role.priority != 3 && role.priority != 6 && role.priority != 7 && role.priority != 8 && role.priority != 9 && role.priority != 10 && role.priority != 11 && role.priority != 12
        })
      }
      if (id == 8) {
        list = result.data.filter(role => {
          return role.priority != 2 && role.priority != 3 && role.priority != 4 && role.priority != 5 && role.priority != 6 && role.priority != 7 && role.priority != 8 && role.priority != 9 && role.priority != 10 && role.priority != 11 && role.priority != 12
        })
      }
      ApiCalls.getRoleBasedMenu(user, id).then((response) => {
        console.log("rolebasedmenu api response: ")
        console.log(response.data);
        setmenuList(response.data);
        if (response.data.length > 0) {
          setmenuList(response.data);
          for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < response.data.length; j++) {
              if (list[i].menuuuid === response.data[j].menuid) {
                list[i].checked = "true";
              }
            }
          }
          setmastermenu(list);
          for (let i = 0; i < menuList.length; i++) {
            let st = {
              userid: userid,
              rolename: s,
              menuid: menuList[i].menuid
            }
            console.log("st inside handleOpen, if")
            console.log(st);
            a.push(st)
          }
          setselectedmenu(a);
        } else {
          if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              list[i].checked = "true";
              let st = {
                userid: userid,
                rolename: s,
                menuid: list[i].menuuuid
              }
              console.log("st inside handleOpen, else")
              console.log(st);
              a.push(st);
            }
            console.log("menulistchecks: ");
            console.log(a)
            setselectedmenu(a);
            setmastermenu(list);
          }
        }
      })
    })

    setopen(true);
  }

  const handleClose = () => {
    setopen(false)
  }

  const handleChangePermissions = (e) => {
    let st = {
      userid: userid,
      rolename: rolename,
      menuid: e.target.name
    }
    console.log("st inside handleChangePermissions")
    console.log(st)
  }

  const disable = () => {
    console.log("rolename: " + rolename)
    setdisableRole(rolename);
    var abc = [];
    abc = roles;
    console.log("abc inside disable")
    console.log(abc)
    // setloading(true);

    let user = secureLocalStorage.getItem('userid');
    user = user.replace(/['"]+/g, '');
    console.log("selectedmenu")
    console.log(selectedmenu)
    ApiCalls.register("ithings/" + user + "/" + rolename + "/insertRoleBasedMenu", selectedmenu)
      .then((response) => {
        console.log(response.data);
        if (response.data.successMessage) {
          setloading(false);
          handleClose();
        }
      })
    secureLocalStorage.setItem('selectedroleId', rolename);
  }

  const finishSetup = () => {
    let userid = secureLocalStorage.getItem('userid');
    ApiCalls.getdetails("ithings/getUserRolelist/" + userid)
      .then((res) => {
        console.log("Role Based List");
        console.log("*******************************");
        console.log(res.data);
        if (res.data.length > 0) {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].roleId == 2 && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
              if (res.data[i].customRoleName != null && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
                console.log("Tenant New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('TenantName', res.data[i].customRoleName);
              }
              else {
                console.log("Tenant New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('TenantName', "Tenant");
              }

            } else {
              console.log("Tenant New Name=" + res.data[i].customRoleName)
              secureLocalStorage.setItem('TenantName', "Tenant");
            }
            if (res.data[i].roleId == 4 && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
              if (res.data[i].customRoleName != null && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
                console.log("Company New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('CompanyName', res.data[i].customRoleName);
              }
              else {
                console.log("Company New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('CompanyName', "Company");
              }

            }
            if (res.data[i].roleId == 6 && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
              if (res.data[i].customRoleName != null && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
                console.log("Physician New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('PhysicianName', res.data[i].customRoleName);
              }
              else {
                console.log("Physician New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('PhysicianName', "Physician");
              }
            }
            if (res.data[i].roleId == 8 && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
              if (res.data[i].customRoleName != null && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
                console.log("Patient New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('PatientName', res.data[i].customRoleName);
              }
              else {
                console.log("Patient New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('PatientName', "Patient");
              }
            }
            if (res.data[i].roleId == 9 && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
              if (res.data[i].customRoleName != null && res.data[i].customRoleName != undefined && res.data[i].customRoleName != "undefined") {
                console.log("Device New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem("DeviceName", res.data[i].customRoleName);
              }
              else {
                console.log("Device New Name=" + res.data[i].customRoleName)
                secureLocalStorage.setItem('DeviceName', "Device");
              }

            }
          }
        } else {
          secureLocalStorage.setItem('TenantName', "Tenant");
          secureLocalStorage.setItem('CompanyName', "Company");
          secureLocalStorage.setItem('PhysicianName', "Physician");
          secureLocalStorage.setItem('PatientName', "Patient");
          secureLocalStorage.setItem('DeviceName', "Device");
        }
      })
    if (isMedicalDomainsChecked) {
      // If the checkbox is checked, show "Finish"

      setnext(true)
    } else {
      // If the checkbox is unchecked, show "Next"
      setnext(false)
    }
  }

  if (next) {
    return (<Redirect to={'/dashboard'} />);
  }

  let showrole = roles.map(function (newrolename, index) {

    return (
      <CssBaseline>
        <Paper style={{ marginTop: "5px", backgroundColor: "#f5f5f5", width: "350px", height: "100px" }}>
          <div
            style={{
              padding: "20px",
              textalign: "left",
              fontSize: "20px",
              color: '#607D8B',
              display: 'flex',
              justifyContent: "space-between",
              textAlign: 'center',
              marginTop: "20px",
            }}
          >
            <p style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginTop: "20px",
            }}>
              {newrolename.customRoleName}
            </p>
            <Button
              color='primary'
              size="medium"
              value={newrolename.customRoleName}
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => handleOpen(e, newrolename.customRoleName, newrolename.roleId)}
            >
              Add Previleges
            </Button>
          </div>
        </Paper>
      </CssBaseline>
    )
  })

  let showmenu = mastermenu.map(function (menuText, index) {
    return (
      <div style={{
        padding: '15px',
        textalign: 'left',
        fontSize: '20px',
      }}>
        <FormControlLabel control={
          <Checkbox key={menuText.menuuuid}
            color="primary"
            value={menuText.menuText}
            onChange={handleChangePermissions}
            defaultChecked={menuText.checked} />
        } label={menuText.menuText} />
      </div>
    )
  })

  return (
    <div>
      <CssBaseline />
      <Container>
        < Grid container spacing={3} >
          <Grid item xs={6}>
            {showrole}
          </Grid>
        </Grid>
        <br /><br />
        <Container style={{ display: 'flex', marginTop: "20px", justifyContent: "space-around" }}>
          <Button
            style={{ marginLeft: '30%' }}
            variant="contained"
            color="primary"
            startIcon={<Icon icon={'mdi:navigate-before'} />}
            onClick={() => (setActiveStep(1))}
          >
            Prev
          </Button>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Icon icon={'mdi:navigate-next'} />}
            onClick={() => {
              finishSetup();
              setActiveStep(activeStep + 1);
            }}
          >
            Next
          </Button>
        </Container>
      </Container>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <DialogContentText className='font-weight-bold text-muted'>
            Grant Permissions for {rolename}
          </DialogContentText>
          <CardHeader title="Select Permissions" />
          <CardContent>
            {showmenu}
          </CardContent>
        </DialogContent>
        <DialogActions>
          {
            loading ? <CircularProgress size={50} /> :
              <div>
                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  style={{
                    marginLeft: '20px'
                  }}
                  onClick={disable}
                >
                  Submit
                </Button>
              </div>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Rolemenu