import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Pump from "../../assets/images/bplpump.png";
import DialogBox from './DialogBox';

const GraphPump = () => {
    const [deviceID, setDeviceID] = useState('');
    const [patientThreshold, setPatientThreshold] = useState({});
    const [trValue, setTrValue] = useState(null);
    const [vtValue, setVtValue] = useState(null);
    const [vlValue, setVlValue] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [slide, setSlide] = useState([
        { min: 30, max: 295 },
        { min: 70, max: 100 },
        { min: 8, max: 35 }
    ]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const deviceId = "100924";
        if (deviceId) {
            setDeviceID(deviceId);
        } else {
            console.warn('deviceId is undefined');
        }
    }, []);

    useEffect(() => {
        setSlide([
            {
                min: parseFloat(patientThreshold ? patientThreshold.hrmin : 30), max: parseFloat(patientThreshold ? patientThreshold.hrmax : 295)
            },
            {
                min: parseFloat(patientThreshold ? patientThreshold.spo2min : 70), max: parseFloat(patientThreshold ? patientThreshold.spo2max : 100)
            },
            {
                min: parseFloat(patientThreshold ? patientThreshold.rrmin : 8), max: parseFloat(patientThreshold ? patientThreshold.rrmax : 35)
            }
        ]);
    }, [patientThreshold]);

    useEffect(() => {
        if (!deviceID) return;

        console.log("Opening WebSocket connection...");

        const ws = new WebSocket("wss://itouch.iorbit.health:8081/consumer");

        ws.onopen = () => {
            console.log('WebSocket connection opened');
            ws.send(JSON.stringify({
                "PacketID": "WS_DEVICE_CONSUME_REQ",
                "DeviceUUID": deviceID,
                "ParamUUID": 20002,
                "SamplingRate": 250,
                "WindowSize": 10,
                "MinYVal": 0.0,
                "MaxYVal": 128.0,
                "isStream": true,
                "labelValues": null
            }));
        };

        ws.onmessage = (e) => {
            try {
                const message = JSON.parse(e.data);
                console.log("Received message:", message);

                if (message && message.valueQuantity) {
                    let value = message.valueQuantity.value;
                    const unit = message.valueQuantity.unit;

                    // Convert value to a number if it's a string
                    if (typeof value === 'string') {
                        value = parseFloat(value);
                    }

                    // Check if the conversion was successful
                    if (!isNaN(value)) {
                        if (unit === 'ml/h') {
                            console.log("The TR value is", value);
                            setTrValue(value);
                        } else if (unit === 'ml') {
                            if (message.code.text.includes("Volume to be Infused")) {
                                console.log("The VT value is", value);
                                setVtValue(value);
                            } else if (message.code.text.includes("Volume Left")) {
                                console.log("The VL value is", value);
                                setVlValue(value);
                            }
                        }
                    } else {
                        console.error('Invalid number format:', value);
                    }
                } else {
                    console.error('Unexpected message format:', message);
                }
            } catch (err) {
                console.error('Error parsing WebSocket message:', err);
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error.message || error);
        };

        ws.onclose = (event) => {
            console.log("WebSocket connection closed:", event);
            if (!event.wasClean) {
                console.error(`Connection closed abnormally with code ${event.code} and reason: ${event.reason}`);
            }
        };

        return () => {
            ws.close();
        };
    }, [deviceID]);

    return (
        <Container>
            <TopSection>
                <Image src={Pump} alt="Pump" />
            </TopSection>
            <BottomSection>
                <InfoContainer>
                    <InfoBox backgroundColor='#1D252E' color='#F5A623' width='30vw'>
                        <div>
                            <ContentText> TR ml/h </ContentText>
                        </div>
                        <LargeText>
                            {trValue !== null ? trValue.toFixed(2) : '-'}
                        </LargeText>
                    </InfoBox>
                    <InfoBox backgroundColor='#1D252E' color='#7ED321' width='30vw'>
                        <div>
                            <ContentText> VT ml </ContentText>
                        </div>
                        <LargeText>
                            {vtValue !== null ? vtValue.toFixed(2) : '-'}
                        </LargeText>
                    </InfoBox>
                    <InfoBox backgroundColor='#1D252E' color='#4A90E2' width='30vw'>
                        <div>
                            <ContentText> VL ml </ContentText>
                        </div>
                        <LargeText>
                            {vlValue !== null ? vlValue.toFixed(2) : '-'}
                        </LargeText>
                    </InfoBox>
                </InfoContainer>
                <DialogBox key={slide} index={index} openDialog={openDialog} setSlide={setSlide} slide={slide} setOpenDialog={setOpenDialog} setIndex={setIndex} />
            </BottomSection>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #080f18;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

const TopSection = styled.div`
    height: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080f18;
`;

const Image = styled.img`
    max-width: 100%;
    height: auto;
    max-height: 100%; 
`;

const BottomSection = styled.div`
    height: 60%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 20px; 
`;

const InfoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
`;

const InfoBox = styled.div`
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    width: ${props => props.width};
    padding: 35px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    text-align: center;
`;

const LargeText = styled.p`
    font-size: 2.5rem;
    margin: 0;
`;

const ContentText = styled.p`
    font-size: 1.7rem;
    margin: 0;
    margin-bottom: 5px; 
`;

export default GraphPump;
